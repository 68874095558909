export const convertTagsToListItems = (htmlString: string): string[] => {
  const ulRegex = /<ul>(.*?)<\/ul>/g;
  const liRegex = /<li>(.*?)<\/li>/g;
  const ulMatches = htmlString.match(ulRegex);
  const listItems: string[] = [];

  if (ulMatches) {
    ulMatches.forEach((ulMatch) => {
      const liMatches = ulMatch.match(liRegex);
      if (liMatches) {
        liMatches.forEach((liMatch) => {
          const strippedLi = liMatch.replace(/<\/?li>/g, "");
          listItems.push(strippedLi);
        });
      }
    });
  }

  return listItems;
};
