import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Flex, InputNumber, Space, Tooltip, Typography } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { useOngoingWalkthrough } from "logic/hooks/ongoing-walkthrough";
import { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { generateSyntheticUsers } from "store/modules/synthetic-users/actions";
import { useDrawerContext } from "templates/index/workspace-page/contexts/drawer";
import {
  StudyContainerTabs,
  useStudyContainerContext,
} from "templates/index/workspace-page/contexts/insights-panel";

type ResearchPlanSubmitProps = {
  disabled: boolean;
};

export const ResearchPlanSubmit: React.FC<ResearchPlanSubmitProps> = ({ disabled }) => {
  const dispatch = useAppDispatch();
  const selectedAudiences = useAppSelector((state) => state.audiences.data.selectedAudiences);
  const selectedProblems = useAppSelector((state) => state.problems.data.selectedProblems);
  const projectId = useAppSelector((state) => state.projects.data.project?.id);
  const { setStudyContainerTab } = useStudyContainerContext();
  const { setDrawerOpen } = useDrawerContext();
  const { ongoingWalkthrough } = useOngoingWalkthrough();

  const initialNumberOfInterviews = useMemo(
    () => (ongoingWalkthrough ? 2 : 3),
    [ongoingWalkthrough]
  );

  const [numberOfInterviews, setNumberOfInterviews] = useState<number | null>(
    initialNumberOfInterviews
  );

  const handleGenerate = useCallback(() => {
    setDrawerOpen(true);
    setStudyContainerTab(StudyContainerTabs.SyntheticUsers);
    if (projectId && numberOfInterviews) {
      dispatch(
        generateSyntheticUsers({
          projectId,
          audiencesIds: selectedAudiences?.map((audience) => audience.id) || [],
          problemsIds: selectedProblems?.map((problem) => problem.id) || [],
          quantity: numberOfInterviews.toString(),
        })
      );
    }
  }, [
    projectId,
    numberOfInterviews,
    selectedAudiences,
    selectedProblems,
    dispatch,
    setDrawerOpen,
    setStudyContainerTab,
  ]);

  return (
    <Flex gap={16} style={{ marginTop: 24 }} align="end">
      <Space direction="vertical" style={{ width: "40%" }}>
        <Typography.Text strong>Number of Interviews</Typography.Text>
        <InputNumber
          controls={false}
          value={numberOfInterviews}
          onChange={setNumberOfInterviews}
          max={10}
          min={1}
          size="large"
          style={{ textAlign: "center", width: "100%", height: 40 }}
          disabled={ongoingWalkthrough}
          addonBefore={
            <Tooltip
              title={
                ongoingWalkthrough
                  ? "You're only allowed to run 2 interviews before creating an account"
                  : ""
              }
            >
              <Button
                size="small"
                shape="circle"
                style={{ backgroundColor: "#000000" }}
                icon={<AntIcon icon={faMinus} style={{ color: "#FFFFFF" }} />}
                onClick={() => setNumberOfInterviews((prev) => (prev && prev > 1 ? prev - 1 : 1))}
                disabled={ongoingWalkthrough}
              />
            </Tooltip>
          }
          addonAfter={
            <Tooltip
              title={
                ongoingWalkthrough
                  ? "You're only allowed to run 2 interviews before creating an account"
                  : ""
              }
            >
              <Button
                size="small"
                shape="circle"
                style={{ backgroundColor: "#000000" }}
                icon={<AntIcon icon={faPlus} style={{ color: "#FFFFFF" }} />}
                onClick={() => setNumberOfInterviews((prev) => (prev && prev < 10 ? prev + 1 : 10))}
                disabled={ongoingWalkthrough}
              />
            </Tooltip>
          }
        />
      </Space>
      <Button
        size="large"
        style={{ width: "60%" }}
        type="primary"
        onClick={handleGenerate}
        disabled={disabled}
      >
        Generate Synthetic Users
      </Button>
    </Flex>
  );
};
