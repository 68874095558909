import { Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";

type TabLabelProps = {
  text: string;
  maxWidth: number;
};

export const TabLabel: React.FC<TabLabelProps> = ({ text, maxWidth }) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const spanElement = spanRef.current;
    if (spanElement) {
      setIsOverflow(spanElement.scrollWidth > spanElement.clientWidth);
    }
  }, [text]);

  return (
    <Tooltip title={isOverflow ? text : ""}>
      <span
        ref={spanRef}
        style={{
          maxWidth,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          display: "block",
        }}
      >
        {text}
      </span>
    </Tooltip>
  );
};
