import { Popover } from "antd";
import React, { useRef, useState } from "react";

export type HighLightWithBubbleMenuProps = {
  children: React.ReactNode;
  content: (
    setIsOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>
  ) => React.ReactNode;
  highlightColor?: string;
  defaultOpen?: boolean;
  id?: string;
  onClose?: () => void;
};

export const HighLightWithBubbleMenu: React.FC<HighLightWithBubbleMenuProps> = ({
  children,
  id,
  content,
  highlightColor,
  defaultOpen,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  // This is a hack to prevent the popover from closing on the first click
  const firstClickRef = useRef(defaultOpen);

  return (
    <Popover
      arrow={false}
      open={isOpen}
      trigger={"click"}
      onOpenChange={(open) => {
        if (firstClickRef.current) {
          firstClickRef.current = false;
          return;
        }

        setIsOpen(open);

        if (!open && onClose) {
          onClose();
        }
      }}
      content={() => content(setIsOpen)}
    >
      <mark data-annotation-id={id} style={{ cursor: "pointer", backgroundColor: highlightColor }}>
        {children}
      </mark>
    </Popover>
  );
};
