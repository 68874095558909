import { Card, List, Typography } from "antd";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { useToken } from "components/ui-kit/core/token";
import { camelCaseToText } from "logic/internals/utils/camel-case-to-text";
import { PolarAngleAxis, PolarGrid, Radar, RadarChart, ResponsiveContainer } from "recharts";
import { GeneratedSyntheticUser } from "store/modules/synthetic-users/types";
import { useDrawerContext } from "templates/index/workspace-page/contexts/drawer";

type UserInterviewUserDetailsProps = {
  // TODO: check why can be null or undefined
  generatedSyntheticUserDetail: GeneratedSyntheticUser | null | undefined;
};

export const PersonaCard: React.FC<UserInterviewUserDetailsProps> = ({
  generatedSyntheticUserDetail,
}) => {
  const token = useToken();
  const { serifFont } = useExtraToken();

  const isValid = !!generatedSyntheticUserDetail;
  const data = generatedSyntheticUserDetail || ({} as GeneratedSyntheticUser);
  const personalData = data.personalInformation || {};
  const personalityTraits = data.personalityTraits || {};
  const miscsData = data.miscellaneous || {};

  const { drawerOpen } = useDrawerContext();

  const chartData = Object.entries(personalityTraits).map(([key, value]: [string, string]) => ({
    key: camelCaseToText(key),
    value: value,
  }));

  return (
    <div style={{ paddingBottom: token.padding, top: drawerOpen ? 125 : 205, position: "sticky" }}>
      <Card
        bordered={false}
        style={{
          backgroundColor: "#FFFFFF",
        }}
      >
        {isValid && (
          <div style={{ marginBottom: token.marginSM }}>
            <Typography.Title
              level={4}
              style={{ marginBottom: token.marginMD, fontFamily: serifFont, fontWeight: 400 }}
            >
              {personalData.fullName}
            </Typography.Title>
            <div style={{ marginLeft: "-35x" }}>
              {!!chartData.length && (
                <ResponsiveContainer height={150} width="100%">
                  <RadarChart outerRadius={38} data={chartData}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="key" />

                    <Radar
                      name={personalData.name}
                      dataKey="value"
                      stroke="#8884d8"
                      fill="#8884d8"
                      fillOpacity={0.6}
                    />
                  </RadarChart>
                </ResponsiveContainer>
              )}
            </div>

            <List
              itemLayout="vertical"
              split={false}
              dataSource={Object.entries({ ...personalData, ...miscsData })}
              renderItem={([key, value]) => {
                // TODO: check why we are filtering this
                if (key.startsWith("Importance")) {
                  return null;
                }

                return (
                  <List.Item style={{ paddingBlock: 2 }}>
                    <Typography.Text strong>{camelCaseToText(key)}:</Typography.Text>
                    <Typography.Text style={{ marginLeft: "10px" }}>{value}</Typography.Text>
                  </List.Item>
                );
              }}
            />
          </div>
        )}
      </Card>
    </div>
  );
};
