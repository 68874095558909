// Components
import { Button, Input, Row } from "antd";
import { useToken } from "components/ui-kit/core/token";
import { useMemo } from "react";
import { GeneratedSyntheticUser } from "store/modules/synthetic-users/types";
// Types

interface AddRequirementInputProps {
  id?: string;
  data: GeneratedSyntheticUser;
  newRequirement: Record<string, string>;
  setNewRequirement: React.Dispatch<
    React.SetStateAction<AddRequirementInputProps["newRequirement"]>
  >;
  onAddRequirement: (data: GeneratedSyntheticUser, newRequirement: Record<string, string>) => void;
}

export function AddRequirementInput({
  id,
  data,
  newRequirement,
  setNewRequirement,
  onAddRequirement,
}: AddRequirementInputProps) {
  const token = useToken();

  const newRequirementKey = useMemo(() => Object.keys(newRequirement)[0], [newRequirement]);
  const newRequirementValue = useMemo(() => Object.values(newRequirement)[0], [newRequirement]);

  const isRepeatedRequirementKey = useMemo(() => {
    if (!newRequirementKey) return false;

    const allKeys = [
      ...Object.keys(data.personalInformation).map((key) => key.toLowerCase()),
      ...Object.keys(data.miscellaneous).map((key) => key.toLowerCase()),
    ];

    const formattedNewRequirementKey = newRequirementKey.replace(/\s/g, "").toLowerCase();

    return allKeys.includes(formattedNewRequirementKey);
  }, [data, newRequirementKey]);

  return (
    <>
      <Row id={id} style={{ marginTop: "16px" }} wrap={false}>
        <Input
          value={newRequirementKey}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNewRequirement({
              [e.target.value]: newRequirementValue || "",
            })
          }
          placeholder="Parameter"
        />
        <p
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            padding: "4px",
          }}
        >
          :
        </p>
        <Input
          value={newRequirementValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNewRequirement({
              [newRequirementKey || ""]: e.target.value,
            })
          }
          placeholder="Value"
        />
        <Button
          type="default"
          style={{ marginLeft: "8px" }}
          onClick={() => {
            if (newRequirementKey && newRequirementValue) {
              onAddRequirement(data, newRequirement);
            }
          }}
          disabled={
            !newRequirementKey?.trim().length ||
            !newRequirementValue?.trim().length ||
            isRepeatedRequirementKey
          }
        >
          Add
        </Button>
      </Row>
      {!!isRepeatedRequirementKey && !!newRequirementKey && (
        <p
          style={{ color: token.red, marginTop: 4, marginBottom: 0 }}
        >{`${newRequirementKey} was already added.`}</p>
      )}
    </>
  );
}
