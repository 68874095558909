import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { z } from "zod";
import { ResearchAssistantResponseSchema, ResearchAssistantSchema } from "./schemas";

export type ResearchAssistant = z.infer<typeof ResearchAssistantSchema>;
export type ResearchAssistantResponse = z.infer<typeof ResearchAssistantResponseSchema>;

export enum ResearchAssistantStudyType {
  DYNAMIC_INTERVIEW = "research_assistant_dynamic_interview",
  PROBLEM_EXPLORATION = "research_assistant_problem_exploration",
  CUSTOM_SCRIPT = "research_assistant_custom_script",
  CONCEPT_TESTING = "research_assistant_concept_testing",
}

export enum ResearchAssistantStatus {
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
}

export type StateProps = {
  loading?: boolean;
  error?: TransportFailure;
  /*
    the idea of loaded data is represented by the truthy value of data,
    hence the need for a string signaling that past interviews were indeed requested
    but there were no past interviews
  */
  data?: {
    id?: string;
    question?: string;
    audience?: string | string[];
    problems?: string | string[];
    solution?: string | string[];
    researchGoal?: string;
    customScript?: string;
    status?: ResearchAssistantStatus;
  };
};
