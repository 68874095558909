import { Button, Divider, Flex } from "antd";
import {
  HighLightWithBubbleMenu,
  HighLightWithBubbleMenuProps,
} from "components/ui-kit/components/highlight-with-bubble-menu";
import React, { useMemo } from "react";
import { AnnotationType, annotationColors, isAnnotationType } from "../../annotations-tab";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type NewAnnotationProps = Omit<HighLightWithBubbleMenuProps, "content"> & {
  onSubmit: (label: string) => void;
  onNavigateToAnnotation?: () => void;
  label?: string;
};

export const UserInterviewAnnotation: React.FC<NewAnnotationProps> = ({
  id,
  label,
  children,
  defaultOpen,
  onClose,
  onSubmit,
  onNavigateToAnnotation,
}) => {
  const annotationColor = useMemo(() => {
    if (isAnnotationType(label)) {
      return annotationColors[label];
    }
  }, [label]);

  const buttons = useMemo(
    () =>
      Object.values(AnnotationType).map((type) => ({
        color: annotationColors[type],
        onClick: () => onSubmit(type),
      })),
    [onSubmit]
  );

  return (
    <HighLightWithBubbleMenu
      id={id}
      highlightColor={annotationColor || annotationColors[AnnotationType.BLUE]}
      defaultOpen={defaultOpen}
      onClose={onClose}
      content={(setIsOpen) => (
        <Flex justify="space-around" gap={6} align="center">
          {buttons.map((button) => (
            <Button
              key={button.color}
              size="small"
              shape="circle"
              style={{
                backgroundColor: button.color,
                border: button.color === annotationColor ? "2px solid #000000" : "none",
              }}
              onClick={() => {
                button.onClick();
                setIsOpen(false);
              }}
            />
          ))}
          {id && (
            <>
              <Divider type="vertical" style={{ height: "24px", marginBlock: 0 }} />
              <AntIcon
                component={() => (
                  <FontAwesomeIcon
                    icon={faList}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onNavigateToAnnotation && onNavigateToAnnotation();
                      setIsOpen(false);
                    }}
                  />
                )}
              />
            </>
          )}
        </Flex>
      )}
    >
      {children}
    </HighLightWithBubbleMenu>
  );
};
