import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Space } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { useToken } from "components/ui-kit/core/token";
import { getAnalytics } from "logic/analytics/analytics";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { CreateReportButton } from "../create-report-button";
import { ExportButton } from "../export-button";
import { RunInterviewsButton } from "../run-interviews-button";
import { SaveStudyButton } from "../save-study-button";
import { ShareButton } from "../share-button";
import {
  InsightsPanelTabKeys,
  StudyContainerTabs,
  useStudyContainerContext,
} from "../../contexts/insights-panel";

interface StudyContainerBarProps {
  studyContainerTab: StudyContainerTabs;
  isInsideDrawer?: boolean;
  insightPanelOpen?: boolean;
  onCloseDrawer?: () => void;
}

export const StudyContainerTopBar: React.FC<StudyContainerBarProps> = ({
  studyContainerTab,
  insightPanelOpen,
  isInsideDrawer,
  onCloseDrawer,
}) => {
  const token = useToken();
  const extraToken = useExtraToken();

  const userInterviews = useAppSelector((state) => state.userInterviews);
  const summary = useAppSelector((state) => state.summary);
  const selectedAudiences = useAppSelector((state) => state.audiences.data.selectedAudiencesIds);
  const analytics = getAnalytics();

  const { setStudyContainerTab, setCurrentAudience, setInsightsPanelTab } =
    useStudyContainerContext();

  const handleInterviewClick = () => {
    if (summary.data && selectedAudiences) {
      setInsightsPanelTab(InsightsPanelTabKeys.SUMMARY);
    } else {
      setCurrentAudience(0);
    }

    setStudyContainerTab(StudyContainerTabs.InsightsPanel);
  };

  const topBarActions = useMemo(() => {
    if (!userInterviews.data) {
      return <RunInterviewsButton />;
    } else if (!summary.data) {
      return <CreateReportButton isVisible={insightPanelOpen === true} />;
    } else {
      return (
        <>
          <ExportButton />
          <SaveStudyButton />
          <ShareButton />
        </>
      );
    }
  }, [insightPanelOpen, summary.data, userInterviews.data]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 80,
        paddingRight: token.paddingLG,
        backgroundColor: extraToken.topBarBgColor,
        position: "sticky",
        top: 0,
        zIndex: 100,
      }}
    >
      <div>
        {!isInsideDrawer && (
          <Button
            disabled={studyContainerTab === StudyContainerTabs.ResearchPlan}
            style={{
              backgroundColor:
                studyContainerTab === StudyContainerTabs.ResearchPlan ? "#33345D" : "#3E3882",
              borderRadius: 0,
              borderColor: "transparent",
              height: 80,
              paddingLeft: token.paddingLG,
              color: "#E5E5E5",
            }}
            onClick={() => {
              analytics.track("workspace:study:switch-tab", { currentTab: "persona_sculpt" });
              setStudyContainerTab(StudyContainerTabs.ResearchPlan);
            }}
          >
            ✍️ Research Plan
          </Button>
        )}
        <Button
          disabled={studyContainerTab === StudyContainerTabs.SyntheticUsers}
          style={{
            backgroundColor:
              studyContainerTab === StudyContainerTabs.SyntheticUsers ? "#33345D" : "#3E3882",
            borderRadius: 0,
            borderColor: "transparent",
            height: 80,
            paddingLeft: token.paddingLG,
            color: "#E5E5E5",
          }}
          onClick={() => {
            analytics.track("workspace:study:switch-tab", { currentTab: "persona_sculpt" });
            setStudyContainerTab(StudyContainerTabs.SyntheticUsers);
          }}
        >
          🧑‍🦲 Synthetic Users
        </Button>
        {!!userInterviews.data && (
          <Button
            style={{
              backgroundColor:
                studyContainerTab === StudyContainerTabs.InsightsPanel ? "#33345D" : "#3E3882",
              borderRadius: 0,
              borderColor: "transparent",
              height: 80,
              paddingLeft: token.paddingLG,
              color: "#E5E5E5",
            }}
            disabled={studyContainerTab === StudyContainerTabs.InsightsPanel}
            onClick={() => {
              analytics.track("workspace:study:switch-tab", { currentTab: "interviews" });
              handleInterviewClick();
            }}
          >
            📄 Interviews and Insights
          </Button>
        )}
      </div>
      <Space direction="horizontal">
        {topBarActions}
        {isInsideDrawer && onCloseDrawer && (
          <Button
            style={{
              marginLeft: token.marginSM,
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            onClick={() => {
              if (studyContainerTab === StudyContainerTabs.SyntheticUsers) {
                analytics.track("workspace:study:persona-sculptor:close");
              } else {
                analytics.track("workspace:study:close-x");
              }
              onCloseDrawer();
            }}
            icon={
              <AntIcon
                component={() => <FontAwesomeIcon icon={faClose} style={{ color: "white" }} />}
              />
            }
          />
        )}
      </Space>
    </div>
  );
};
