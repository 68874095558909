import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Col, Empty, Row, Tabs, TabsProps, Tooltip, Typography, theme } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { getAnalytics } from "logic/analytics/analytics";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { updateSyntheticUsers } from "store/modules/synthetic-users/actions";
import { GeneratedSyntheticUser } from "store/modules/synthetic-users/types";
import { EducationalLoading } from "./components/educational-loading";
import { SyntheticUserCard } from "./components/su-card";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { useDrawerContext } from "../../contexts/drawer";

export const GenerateSyntheticUsers: React.FC = () => {
  const { token } = theme.useToken();
  const { serifFont } = useExtraToken();
  const dispatch = useAppDispatch();
  const analytics = getAnalytics();
  const syntheticUsers = useAppSelector((state) => state.syntheticUsers);
  const userInterviews = useAppSelector((state) => state.userInterviews.data);
  const { setDrawerOpen } = useDrawerContext();

  const [isMultipleAudiences, setIsMultipleAudiences] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("");

  const handleAddNewRequirement = (
    data: GeneratedSyntheticUser,
    newRequirement: Record<string, string>
  ) => {
    const updatedSU: GeneratedSyntheticUser = {
      ...data,
      miscellaneous: { ...data.miscellaneous, ...newRequirement },
    };

    analytics.track("workspace:study:persona-sculptor:add-param", {
      synthetic_user_id: data.id,
      new_param_key: newRequirement.key,
    });

    updateGeneratedSyntheticUser(updatedSU);
  };

  const handleDeleteRequirement = (data: GeneratedSyntheticUser, requirementKey: string) => {
    if (!data.miscellaneous) return;

    const updatedSU: GeneratedSyntheticUser = structuredClone(data);
    delete updatedSU.miscellaneous[requirementKey];

    analytics.track("workspace:study:persona-sculptor:remove-param", {
      synthetic_user_id: data.id,
      removed_param: requirementKey,
    });

    updateGeneratedSyntheticUser(updatedSU);
  };

  const handleUpdateRequirement = (
    data: GeneratedSyntheticUser,
    requirementKey: string,
    newValue: string
  ) => {
    if (!data.miscellaneous) return;

    const updatedSU: GeneratedSyntheticUser = {
      ...data,
      miscellaneous: {
        ...data.miscellaneous,
        [requirementKey]: newValue,
      },
    };

    analytics.track("workspace:study:persona-sculptor:update-param", {
      synthetic_user_id: data.id,
      updated_key: requirementKey,
      new_param_key: newValue,
    });

    updateGeneratedSyntheticUser(updatedSU);
  };

  const updateGeneratedSyntheticUser = (data: GeneratedSyntheticUser) => {
    dispatch(updateSyntheticUsers({ syntheticUserId: data.id, data: flattenData(data) }));
  };

  const audienceGroups = syntheticUsers.data?.reduce<Record<string, GeneratedSyntheticUser[]>>(
    (groups, item) => {
      const groupKey = item.syntheticUserId;
      const group = groups[groupKey] || [];
      group.push(item);
      groups[groupKey] = group;
      return groups;
    },
    {} as Record<string, GeneratedSyntheticUser[]> // Specify the type here
  );

  const flattenData = (data: GeneratedSyntheticUser): string => {
    // Function to convert camelCase to normal case
    const camelCaseToNormalCase = (str: string): string => {
      return str
        .replace(/([A-Z])/g, " $1") // insert a space before all capital letters
        .replace(/^./, (str) => str.toUpperCase()); // capitalize the first character
    };

    // Destructuring
    const { personalInformation, personalityTraits, miscellaneous } = data;

    // Defining categories
    const categories = [personalInformation, personalityTraits, miscellaneous];

    let result = "";

    // Iterating over categories
    for (const category of categories) {
      // Checking if category is not null
      if (category) {
        for (const key in category) {
          const value = category[key];
          if (value) {
            result += `${camelCaseToNormalCase(key)}: ${value}, `;
          }
        }
      }
    }

    // Remove trailing comma and space
    return result.slice(0, -2);
  };

  useEffect(() => {
    const firstSyntheticUserId = syntheticUsers.data?.[0]?.syntheticUserId;
    const multipleAudience =
      !!syntheticUsers.data &&
      !syntheticUsers.data?.every((user) => user.syntheticUserId === firstSyntheticUserId);

    setIsMultipleAudiences(multipleAudience);
    setSelectedTab(firstSyntheticUserId || "");
  }, [syntheticUsers]);

  const items: TabsProps["items"] =
    audienceGroups &&
    Object.keys(audienceGroups).map((key) => ({
      key: key,
      label:
        audienceGroups[key] && audienceGroups[key]?.[0] ? (
          <Tooltip title={audienceGroups[key]?.[0]?.syntheticUserDescription}>
            <div
              style={{
                maxWidth: 450,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {audienceGroups[key]?.[0]?.syntheticUserDescription}
            </div>
          </Tooltip>
        ) : null,
      disabled: false,
      children: key === selectedTab && (
        <Row gutter={[16, 16]}>
          {audienceGroups[key]?.map((user) => (
            <Col key={user.id} xs={{ span: 12 }} md={{ span: 8 }}>
              <SyntheticUserCard
                data={user}
                onAddRequirement={handleAddNewRequirement}
                onDeleteRequirement={handleDeleteRequirement}
                onUpdateRequirement={handleUpdateRequirement}
                isEditable={!userInterviews}
                loading={syntheticUsers.loading}
              />
            </Col>
          ))}
        </Row>
      ),
    }));

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "grid",
        padding: 50,
        gridTemplateRows: "1fr auto",
      }}
    >
      {/* Empty state */}
      {!syntheticUsers.data && !syntheticUsers.loading && (
        <Empty
          image={null}
          description={
            <Typography.Text strong type="secondary">
              {"No Synthetic Users Generated."}
              <br />
              {
                "Please go back to the main screen to generate Synthetic Users with the desired inputs."
              }
            </Typography.Text>
          }
        >
          <Button
            icon={<AntIcon component={() => <FontAwesomeIcon icon={faArrowLeft} />} />}
            type="primary"
            onClick={() => setDrawerOpen(false)}
          >
            Go Back
          </Button>
        </Empty>
      )}

      {/* Error state */}
      {syntheticUsers.error && (
        <div style={{ marginBottom: token.marginSM }}>
          {syntheticUsers.error === TransportFailure.UserUnautorized && (
            <Alert message={"User unauthorized."} type="error" />
          )}
          {syntheticUsers.error === TransportFailure.InternalServerError && (
            <Alert message={"Unexpected error while updating synthetic user."} type="error" />
          )}
        </div>
      )}

      {!!syntheticUsers.loading && !syntheticUsers.data && (
        <div style={{ textAlign: "center" }}>
          <Typography.Title level={5} style={{ paddingBottom: token.paddingXS }}>
            Whilst we create your Synthetic Users:
          </Typography.Title>
          <EducationalLoading />
        </div>
      )}

      {syntheticUsers.data && (
        <div style={{ paddingBottom: token.padding }}>
          <Typography.Title
            level={4}
            style={{ marginBottom: 24, fontFamily: serifFont, fontWeight: 400 }}
          >
            Participants
          </Typography.Title>
          {isMultipleAudiences ? (
            <div>
              <Tabs activeKey={selectedTab} onChange={setSelectedTab} items={items} />
            </div>
          ) : (
            <Row gutter={[16, 16]}>
              {syntheticUsers.data?.map((card) => (
                <Col key={card.id} xs={{ span: 12 }} md={{ span: 8 }}>
                  <SyntheticUserCard
                    data={card}
                    onAddRequirement={handleAddNewRequirement}
                    onDeleteRequirement={handleDeleteRequirement}
                    onUpdateRequirement={handleUpdateRequirement}
                    loading={syntheticUsers.loading}
                    isEditable={!userInterviews}
                  />
                </Col>
              ))}
            </Row>
          )}
        </div>
      )}
    </div>
  );
};
