import { Button, Select } from "antd";
import { getAnalytics } from "logic/analytics/analytics";
import useDebouncedCallback from "logic/hooks/use-debounce";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  generateUserInterviews,
  generateUserInterviewsNew,
} from "store/modules/user-interviews/actions";
import { useSidebarMenuContext } from "components/routing/page/layouts/with-sidebar-menu/context";
import { useState } from "react";
import { useOngoingWalkthrough } from "logic/hooks/ongoing-walkthrough";
import { StudyContainerTabs, useStudyContainerContext } from "../../contexts/insights-panel";

export function RunInterviewsButton() {
  const project = useAppSelector((state) => state.projects.data);
  const syntheticUsers = useAppSelector((state) => state.syntheticUsers.data);
  const userInterviews = useAppSelector((state) => state.userInterviews);

  const { setCurrentPage, setViewByTopic, setStudyContainerTab } = useStudyContainerContext();
  const { betaUIEnabled } = useSidebarMenuContext();
  const { ongoingWalkthrough, markWalkthroughAsDone } = useOngoingWalkthrough();

  const [selectedLanguage, setSelectedLanguage] = useState("english"); // Default language set to English
  const dispatch = useAppDispatch();
  const analytics = getAnalytics();

  const handleButtonClick = useDebouncedCallback(() => {
    setStudyContainerTab(StudyContainerTabs.InsightsPanel);
    setCurrentPage(0);
    setViewByTopic(false);

    if (project?.project?.id) {
      analytics.track("workspace:insights:generate-user-interviews", {
        quantity: syntheticUsers?.length || 3,
      });

      dispatch(
        betaUIEnabled
          ? generateUserInterviewsNew({
              projectId: project?.project?.id,
              interviews: syntheticUsers?.length || 3,
              language: selectedLanguage,
              ongoingWalkthrough,
            })
          : generateUserInterviews({
              projectId: project?.project?.id,
              interviews: syntheticUsers?.length || 3,
              language: selectedLanguage,
              ongoingWalkthrough,
            })
      );
    }

    ongoingWalkthrough && markWalkthroughAsDone();
  });

  return (
    <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
      <div
        style={{ backgroundColor: "white", borderRadius: "6px", flex: 1 }}
        hidden={!!userInterviews.data}
      >
        <Select
          style={{ width: "180px" }}
          placeholder="Select a language"
          onChange={setSelectedLanguage}
          defaultValue={selectedLanguage}
          options={[
            { label: "English", value: "english" },
            { label: "Spanish", value: "spanish" },
            { label: "French", value: "french" },
            { label: "German", value: "german" },
            { label: "Brazilian Portuguese", value: "brazilian portuguese" },
            { label: "Japanese", value: "japanese" },
            { label: "Italian", value: "italian" },
          ]}
        />
      </div>
      <div
        style={{ backgroundColor: "white", borderRadius: "6px", flex: 1 }}
        hidden={!!userInterviews.data}
      >
        <Button onClick={handleButtonClick} type="primary" disabled={!syntheticUsers?.length}>
          Run Interviews
        </Button>
      </div>
    </div>
  );
}
