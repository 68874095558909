import { Card, Col, Row, Space, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { addResearchGoal } from "store/modules/research-goal/actions";
import { ResearchPlanInput } from "./research-plan-input";
import { ResearchPlanSubmit } from "./research-plan-submit";
import { addAudienceNew, updateAudienceNew } from "store/modules/audiences/actions/new-ui";
import { addSolutionNew, updateSolutionNew } from "store/modules/solutions/actions/new-ui";
import { addProblemsNew, updateProblemNew } from "store/modules/problems/actions/new-ui";
import { LoadingStatus } from "store/types";
import { ResearchAssistantStatus } from "store/modules/research-assistant/types";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { addCustomScript } from "store/modules/custom-script/actions";
import { useResearchAssistantReady } from "logic/hooks/betaUI/handle-research-assistant-ready";
import { ProjectSettings } from "store/modules/projects/types";

type rec = {
  id: string;
  description: string;
  createdAt: string;
};

const inputLabelMapping = {
  audience: "Audience",
  problems: "Problems",
  solution: "Solution",
  researchGoal: "Research Goal",
  customScript: "Custom Script",
};

export type ResearchPlanProps = {
  assistantData?: {
    audience?: string | string[];
    problems?: string | string[];
    solution?: string | string[];
    researchGoal?: string;
    customScript?: string;
  };
  data?: {
    audience?: rec[];
    problems?: rec[];
    solution?: rec[];
    researchGoal?: rec;
    customScript?: string;
  };
  isReady?: boolean;
  setIsReady: (isReady: boolean) => void;
  isReadOnly?: boolean;
  interviewMode?: ProjectSettings;
};

function isRec(value: string | rec | rec[]): value is rec {
  return (
    typeof value === "object" &&
    value !== null &&
    "id" in value &&
    typeof value.id === "string" &&
    "description" in value &&
    typeof value.description === "string" &&
    "createdAt" in value &&
    typeof value.createdAt === "string"
  );
}

export const ResearchPlan: React.FC<ResearchPlanProps> = ({
  assistantData,
  data,
  isReady,
  setIsReady,
  isReadOnly,
  interviewMode,
}) => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector((state) => state.projects.data.project?.id);
  const researchAssistant = useAppSelector((state) => state.researchAssistant);
  const audiencesLoading = useAppSelector((state) => state.audiences.loading);
  const problemsLoading = useAppSelector((state) => state.problems.loading);
  const solutionsLoading = useAppSelector((state) => state.solutions.loading);
  const researchGoalLoading = useAppSelector((state) => state.researchGoal.loading);
  const syntheticUsers = useAppSelector((state) => state.syntheticUsers.data);

  const [formValues, setFormValues] = useState<Record<string, string>>({});

  const submitDisabled = useMemo(() => {
    const hasEntries = Object.entries(formValues).length;
    return hasEntries ? Object.values(formValues).some((value) => !value) : true;
  }, [formValues]);

  const { serifFont } = useExtraToken();

  const readOnly = useMemo(
    () => isReadOnly || !!syntheticUsers?.length,
    [isReadOnly, syntheticUsers]
  );

  const assistantDataCount = useMemo(
    () => Object.keys(assistantData || {}).length,
    [assistantData]
  );

  const dataCount = useMemo(() => Object.keys(data || {}).length, [data]);

  const formattedAssistantData = useMemo(() => {
    return Object.entries(assistantData || {}).map(([key, value]) => ({
      resourceKey:
        interviewMode === ProjectSettings.MessageTestingMode && key === "solution"
          ? "Messaging"
          : inputLabelMapping[key as keyof typeof inputLabelMapping],
      entries: Array.isArray(value) ? (!value.length ? [""] : value) : [value],
    }));
  }, [assistantData, interviewMode]);

  const formattedData = useMemo(() => {
    const mapLoading: Record<string, boolean> = {
      audience: audiencesLoading !== LoadingStatus.none,
      problems: problemsLoading !== LoadingStatus.none,
      solution: solutionsLoading !== LoadingStatus.none,
      researchGoal: !!researchGoalLoading,
    };

    return Object.entries(data || {}).map(([key, value]) => {
      const resourceKey =
        interviewMode === ProjectSettings.MessageTestingMode && key === "solution"
          ? "Messaging"
          : inputLabelMapping[key as keyof typeof inputLabelMapping];

      // initial form values to keep track of changes (for submit)
      setFormValues((prev) => {
        if (typeof value === "string") {
          return { ...prev, [`${resourceKey}-0`]: value };
        } else if (Array.isArray(value) && !!value.length) {
          const entries = value
            .map((item, i) => ({ [`${resourceKey}-${i}`]: item.description }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});
          return { ...prev, ...entries };
        } else if (isRec(value)) {
          return { ...prev, [`${resourceKey}-0`]: value.description };
        } else {
          return prev;
        }
      });

      return {
        resourceKey,
        entries: Array.isArray(value) ? value : [value],
        loading: mapLoading[key],
        onChange: (key: string, value: string) => {
          setFormValues((prev) => ({ ...prev, [key]: value }));
        },
        onSubmit: (value: string, id?: string) => {
          if (!projectId) return;
          switch (key) {
            case "audience":
              if (id) {
                dispatch(updateAudienceNew({ projectId, audienceId: id, description: value }));
              } else {
                dispatch(addAudienceNew({ projectId, description: value }));
              }
              break;
            case "solution":
              if (id) {
                dispatch(updateSolutionNew({ projectId, description: value, solutionId: id }));
              } else {
                dispatch(addSolutionNew({ projectId, description: value }));
              }
              break;
            case "problems":
              if (id) {
                dispatch(
                  updateProblemNew({ projectId: projectId, problemId: id, description: value })
                );
              } else {
                dispatch(addProblemsNew({ projectId: projectId, descriptions: [value] }));
              }
              break;
            case "researchGoal":
              dispatch(addResearchGoal({ projectId: projectId, description: value }));
              break;
            case "customScript":
              dispatch(addCustomScript(value));
              break;
          }
        },
      };
    });
  }, [
    data,
    dispatch,
    projectId,
    audiencesLoading,
    problemsLoading,
    solutionsLoading,
    researchGoalLoading,
    interviewMode,
  ]);

  const handleReady = useResearchAssistantReady({ assistantData, setIsReady });

  useEffect(() => {
    if (researchAssistant.data?.status === ResearchAssistantStatus.COMPLETED && !isReady) {
      handleReady();
    }
  }, [researchAssistant.data?.status, isReady, handleReady]);

  return (
    <>
      <Card bordered={false} style={{ backgroundColor: "#DDDDEE" }}>
        <Typography.Title level={4} style={{ fontFamily: serifFont, fontWeight: 400 }}>
          Your Research Plan
        </Typography.Title>
        <Row gutter={16}>
          {/* Research Assistant On (readonly mode) */}
          {!readOnly &&
            !isReady &&
            formattedAssistantData.map((data) => (
              <Col span={24 / assistantDataCount} key={data.resourceKey}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Typography.Text strong>{data.resourceKey}</Typography.Text>
                  {data.entries.map((entry, i) => (
                    <Card
                      key={entry}
                      style={{
                        backgroundColor: "#F1F1F8",
                        minHeight: i === 0 ? 120 : 60,
                      }}
                      size="small"
                    >
                      <Typography.Text
                        style={{
                          color: entry.length ? "#000000" : "rgba(0, 0, 0, 0.45)",
                        }}
                      >
                        {entry.length
                          ? entry
                          : `Your ${data.resourceKey.toLocaleLowerCase()} will appear here...`}
                      </Typography.Text>
                    </Card>
                  ))}
                </Space>
              </Col>
            ))}

          {/* Research Assistant Off (editable mode) */}
          {!readOnly &&
            isReady &&
            formattedData.map((data) => (
              <Col span={24 / dataCount} key={data.resourceKey}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Typography.Text strong>{data.resourceKey}</Typography.Text>
                  {data.entries.map((entry, i) => (
                    <ResearchPlanInput
                      key={typeof entry !== "string" ? entry?.id : undefined}
                      id={typeof entry !== "string" ? entry?.id : undefined}
                      defaultValue={typeof entry !== "string" ? entry?.description : entry}
                      isEditable={isReady}
                      onSubmit={data.onSubmit}
                      onInputChange={(value) => data.onChange(`${data.resourceKey}-${i}`, value)}
                      loading={data.loading}
                      compact={i !== 0}
                    />
                  ))}
                </Space>
              </Col>
            ))}

          {/* Research Assistant Off (existing study) */}
          {readOnly &&
            formattedData.map((data) => (
              <Col span={24 / dataCount} key={data.resourceKey}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Typography.Text strong>{data.resourceKey}</Typography.Text>
                  {data.entries.map((entry, i) => (
                    <Card
                      key={typeof entry !== "string" ? entry?.id : entry}
                      style={{ backgroundColor: "#FFFFFF", minHeight: i === 0 ? 120 : 60 }}
                      size="small"
                    >
                      <Typography.Text style={{ whiteSpace: "pre-wrap" }}>
                        {typeof entry !== "string" ? entry?.description : entry}
                      </Typography.Text>
                    </Card>
                  ))}
                </Space>
              </Col>
            ))}
        </Row>

        {/* Submit research plan */}
        {isReady && !readOnly && <ResearchPlanSubmit disabled={submitDisabled} />}
      </Card>
    </>
  );
};
