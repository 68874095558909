import { ReactNode, useEffect, useMemo, useState } from "react";
import {
  StudyContainerTabs,
  INTERVIEW_TAB_KEY,
  InsightsPanelTab,
  InsightsPanelTabKeys,
  StudyContainerContext,
} from ".";
import { useAppSelector } from "store/hooks";
import { useRouter } from "next/router";

type StudyContainerContextProviderProps = {
  children: ReactNode;
};

export const StudyContainerContextProvider: React.FC<StudyContainerContextProviderProps> = ({
  children,
}) => {
  const summaryData = useAppSelector((state) => state.summary.data);
  const userInterviews = useAppSelector((state) => state.userInterviews);
  const syntheticUsersData = useAppSelector((state) => state.syntheticUsers.data);
  const router = useRouter();
  const { studyId } = router.query;

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [viewByTopic, setViewByTopic] = useState(false);
  const [currentTopic, setCurrentTopic] = useState(0);
  const [totalTopics, setTotalTopics] = useState(0);
  const [currentAudience, setCurrentAudience] = useState(0);
  const [currentSummaryIndex, setCurrentSummaryIndex] = useState(0);
  const [insightsPanelTab, setInsightsPanelTab] = useState<InsightsPanelTab>(
    summaryData ? InsightsPanelTabKeys.SUMMARY : `${INTERVIEW_TAB_KEY}0`
  );
  const [studyContainerTab, setStudyContainerTab] = useState<StudyContainerTabs>(
    studyId ? StudyContainerTabs.InsightsPanel : StudyContainerTabs.SyntheticUsers
  );

  const currInterviewsTotalTopics = useMemo(() => {
    const firstInterview = userInterviews.data && userInterviews;
    [0];

    if (firstInterview) return firstInterview.data?.[0]?.data.topics?.length;
  }, [userInterviews]);

  const currInterviewsTotalPages = useMemo(() => syntheticUsersData?.length, [syntheticUsersData]);

  const resetStudyContainerContext = () => {
    setCurrentPage(0);
    setTotalPages(0);
    setViewByTopic(false);
    setCurrentTopic(0);
    setTotalTopics(0);
    setCurrentAudience(0);
    setInsightsPanelTab(`${INTERVIEW_TAB_KEY}0`);
    setStudyContainerTab(StudyContainerTabs.SyntheticUsers);
  };

  useEffect(() => {
    if (!studyId) return;

    if (summaryData?.length) {
      setInsightsPanelTab(InsightsPanelTabKeys.SUMMARY);
    } else {
      setInsightsPanelTab(`${INTERVIEW_TAB_KEY}0`);
    }

    setStudyContainerTab(StudyContainerTabs.InsightsPanel);
  }, [studyId, summaryData]);

  useEffect(() => {
    if (currInterviewsTotalPages) {
      setCurrentPage(0);
      setTotalPages(currInterviewsTotalPages - 1);
    }
  }, [currInterviewsTotalPages]);

  useEffect(() => {
    if (currInterviewsTotalTopics) {
      setCurrentTopic(0);
      setTotalTopics(currInterviewsTotalTopics - 1);
    }
  }, [currInterviewsTotalTopics]);

  return (
    <StudyContainerContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        totalPages,
        setTotalPages,
        viewByTopic,
        setViewByTopic,
        currentTopic,
        setCurrentTopic,
        totalTopics,
        setTotalTopics,
        currentAudience,
        setCurrentAudience,
        currentSummaryIndex,
        setCurrentSummaryIndex,
        insightsPanelTab,
        setInsightsPanelTab,
        studyContainerTab,
        setStudyContainerTab,
        resetStudyContainerContext,
      }}
    >
      {children}
    </StudyContainerContext.Provider>
  );
};
