import { ReloadOutlined } from "@ant-design/icons";
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faComment,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Flex, Result, Space, Typography, theme } from "antd";
import { ErrorFeedback } from "components/shared/error-feedback";
import { Spinner } from "components/ui-kit/components/spinner";
import { getAnalytics } from "logic/analytics/analytics";
import { isTransportFailure } from "logic/internals/transports/transported-data/is-transport-failure";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { SignupRequiredTrigger } from "templates/index/workspace-page/signup-required-alert/signup-required-trigger";
import { CreateReportButton } from "../../../create-report-button";
import { SummaryFollowUpContainer } from "./summary-follow-up";
import { CreateReportModal } from "../../../create-report-modal";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { generateSummary } from "store/modules/summaries/actions";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { WorkspaceMode } from "store/modules/workspaces/types";
import { useStudyContainerContext } from "templates/index/workspace-page/contexts/insights-panel";

export const SummaryTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const { token } = theme.useToken();
  const { serifFont } = useExtraToken();

  // Store state
  const workspaceMode = useAppSelector((state) => state.workspaces.data.workspaceMode);
  const summary = useAppSelector((state) => state.summary);
  const userInterviews = useAppSelector((state) => state.userInterviews);

  // FEATURE FLAG
  const isReportFocusEnabled = useFeatureFlagEnabled("report-focus");
  const chatWithReportEnabled = useFeatureFlagEnabled("chat-with-report");

  const { currentSummaryIndex, setCurrentSummaryIndex } = useStudyContainerContext();

  const summariesCount = useMemo(() => summary.data?.length ?? 0, [summary.data]);

  const currentSummaryData = useMemo(() => {
    return summary.data && summary.data[currentSummaryIndex];
  }, [summary, currentSummaryIndex]);

  const analytics = getAnalytics();

  // Local state
  const [successfullyCopied, setSuccessfullyCopied] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);

  const summaryFollowUpContainerRef = useRef<HTMLDivElement>(null);

  const doneGeneratingSummary = useMemo(() => {
    const allUserInterviews = Object.values(userInterviews.data || {});
    return (
      !!currentSummaryData?.summary.length &&
      !summary.loading &&
      !summary.error &&
      !userInterviews.loading &&
      !userInterviews.error &&
      !allUserInterviews.some((userInterviewState) => userInterviewState.loading) &&
      !allUserInterviews.some((userInterviewState) => userInterviewState.error)
    );
  }, [userInterviews, summary, currentSummaryData?.summary]);

  const summaryArr = useMemo(() => {
    // Handle summary from existing study (has messages array)
    const mappedMessages = currentSummaryData?.messages?.map((message) => message.content);
    if (mappedMessages?.length) return mappedMessages;

    // Handle summary that's being currently created (streamed)
    return Array.isArray(currentSummaryData?.summary)
      ? currentSummaryData?.summary
      : [currentSummaryData?.summary];
  }, [currentSummaryData?.messages, currentSummaryData?.summary]);

  const onCopyClick = useCallback(() => {
    analytics.track("workspace:report:copy-report");
    const value = summaryArr.join("\n\n");
    navigator.clipboard.writeText(value);
    setSuccessfullyCopied(true);
  }, [summaryArr, analytics]);

  const scrollToBottom = () => {
    summaryFollowUpContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleRegenerate = useCallback(() => {
    if (isReportFocusEnabled) {
      setShowRegenerateModal(true);
    } else {
      analytics.track("workspace:report:regenerate");
      dispatch(generateSummary({ summarizationFocus: undefined }));
    }
  }, [dispatch, analytics, isReportFocusEnabled]);

  const handleNextSummary = useCallback(() => {
    const isLast = currentSummaryIndex === summariesCount - 1;
    if (isLast) return setCurrentSummaryIndex(0);
    setCurrentSummaryIndex((prev) => prev + 1);
  }, [currentSummaryIndex, setCurrentSummaryIndex, summariesCount]);

  const handlePrevSummary = useCallback(() => {
    const isFirst = currentSummaryIndex === 0;
    if (isFirst) return setCurrentSummaryIndex(summariesCount - 1);
    setCurrentSummaryIndex((prev) => prev - 1);
  }, [currentSummaryIndex, setCurrentSummaryIndex, summariesCount]);

  useEffect(() => {
    if (successfullyCopied) {
      setTimeout(() => {
        setSuccessfullyCopied(false);
      }, 2000);
    }
  }, [successfullyCopied]);

  return (
    <>
      <style jsx global>
        {`
          /* styles.css */
          .markdown {
            table {
              border-collapse: collapse;
              margin: 16px;
            }

            th,
            td {
              padding: 6px 13px;
              border: 1px solid black;
            }

            p {
              line-height: 1.5;
            }
          }
        `}
      </style>
      <div
        style={{
          maxWidth: "725px",
          alignContent: "center",
          margin: "auto",
          backgroundColor: "#FAFAFA",
          padding: token.paddingXL,
        }}
      >
        {/* Default error state */}
        {isTransportFailure(summary.error) && (
          <ErrorFeedback error={summary.error} style={{ marginBottom: token.margin }} />
        )}
        {/* Too many tokens error state */}
        {summary.error === "too_many_tokens" && (
          <Result
            status={"warning"}
            title={"Total interviews size exceeded"}
            subTitle={"The total of user interviews exceeded the summarization limit of the model."}
          />
        )}
        {currentSummaryData?.summaryId && !currentSummaryData?.summary.length && (
          <div>
            <Spinner
              size="large"
              style={{ marginTop: token.padding, marginBottom: token.padding }}
            />
          </div>
        )}
        {currentSummaryData?.summary === undefined && !summary.loading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CreateReportButton isVisible={true} />
          </div>
        ) : (
          <CreateReportModal visible={showRegenerateModal} setVisible={setShowRegenerateModal} />
        )}

        {!!currentSummaryData?.summary.length && (
          <div>
            <Flex justify="space-between" align="center">
              <Space>
                <Typography.Title
                  level={4}
                  style={{ margin: 0, alignSelf: "center", fontFamily: serifFont, fontWeight: 400 }}
                >
                  Full Report
                </Typography.Title>
                {summary.data?.length && summary.data?.length > 1 && (
                  <Space align="center" size="small">
                    <AntIcon
                      component={() => (
                        <FontAwesomeIcon
                          size="xs"
                          style={{ cursor: "pointer" }}
                          icon={faChevronLeft}
                          onClick={handlePrevSummary}
                        />
                      )}
                    />
                    <Typography.Text style={{ fontSize: 10 }}>
                      {currentSummaryIndex + 1} / {summariesCount}
                    </Typography.Text>
                    <AntIcon
                      component={() => (
                        <FontAwesomeIcon
                          size="xs"
                          style={{ cursor: "pointer" }}
                          icon={faChevronRight}
                          onClick={handleNextSummary}
                        />
                      )}
                    />
                  </Space>
                )}
              </Space>
              {workspaceMode !== WorkspaceMode.SharedUrl && (
                <div>
                  <SignupRequiredTrigger style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      disabled={!doneGeneratingSummary}
                      icon={<AntIcon icon={faComment} />}
                      onClick={() => {
                        analytics.track("workspace:study:summary:follow-up", {
                          summary_id: currentSummaryData?.summaryId ?? "",
                        });
                        scrollToBottom();
                      }}
                    >
                      Ask Questions
                    </Button>
                    <Button
                      style={{ marginLeft: token.margin }}
                      disabled={!doneGeneratingSummary}
                      icon={<ReloadOutlined />}
                      onClick={handleRegenerate}
                    >
                      Regenerate
                    </Button>

                    <Button
                      style={{ marginLeft: token.margin }}
                      onClick={onCopyClick}
                      disabled={!doneGeneratingSummary}
                    >
                      Copy
                      <FontAwesomeIcon
                        style={{ marginLeft: token.marginXS }}
                        icon={successfullyCopied ? faCheck : faCopy}
                      />
                    </Button>
                  </SignupRequiredTrigger>
                </div>
              )}
            </Flex>
            {currentSummaryData.summarizationFocus && (
              <Typography.Paragraph type="secondary" style={{ marginBlock: 24 }}>
                <strong>Report Focus:</strong> {currentSummaryData.summarizationFocus}
              </Typography.Paragraph>
            )}
            <div>
              <Space
                direction="vertical"
                size="large"
                style={{ width: "100%", marginTop: currentSummaryData.summarizationFocus ? 0 : 24 }}
              >
                {summaryArr?.map((summarySection, i) => {
                  if (!summarySection) return null;
                  return (
                    <Card
                      key={`summary-card-${i}`}
                      style={{
                        borderBlockColor: "transparent",
                        borderLeftColor: "transparent",
                        borderRightColor: "transparent",
                      }}
                    >
                      <ReactMarkdown className="markdown" remarkPlugins={[gfm]}>
                        {summarySection}
                      </ReactMarkdown>
                    </Card>
                  );
                })}
              </Space>
            </div>
            {doneGeneratingSummary && chatWithReportEnabled && (
              <Card style={{ marginTop: token.margin, borderColor: "transparent" }}>
                <div ref={summaryFollowUpContainerRef}>
                  <SummaryFollowUpContainer summaryId={currentSummaryData?.summaryId ?? ""} />
                </div>
              </Card>
            )}
          </div>
        )}
      </div>
    </>
  );
};
