import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { InterviewAnnotation } from "store/modules/user-interviews/types";

export const getStudyAnnotations = createSelector(
  (state: RootState) => state,
  (state) => {
    const { userInterviews } = state;

    return (
      Object.values(userInterviews.data || {})
        .map((userInterview) => {
          // Get the synthetic user name
          const syntheticUserName =
            userInterview.data?.generatedSyntheticUserPersonalityDetails?.personalInformation
              .fullName || "";

          // Sort annotations by start index
          const sortedAnnotations: InterviewAnnotation[] = [
            ...(userInterview.data.annotations || []),
          ].sort((a, b) => a.startIndex - b.startIndex);

          // Get the conversation ids order
          const conversationIdsOrder = userInterview.data.userInterviewWithTypes?.map(
            (interview) => interview.conversation_id
          );

          // Initialize annotations object
          const annotations: {
            [key: string]: { question: string; annotations: InterviewAnnotation[] };
          } = {};

          // Group annotations by conversation id
          conversationIdsOrder?.forEach((conversationId) => {
            // Get annotations for the conversation
            const conversationAnnotations = sortedAnnotations.filter(
              (annotation) => annotation.conversationId === conversationId
            );
            // Get the index of the conversation in the userInterviewWithTypes array
            const conversationIndex = userInterview.data.userInterviewWithTypes?.findIndex(
              (interview) => interview.conversation_id === conversationId
            );
            // Get the question for the conversation (previous conversation message)
            const question =
              conversationIndex && userInterview.data.userInterviewWithTypes
                ? userInterview.data.userInterviewWithTypes[conversationIndex - 1]?.message || ""
                : "";
            // If there are annotations for the conversation, add them to the annotations object
            if (conversationAnnotations.length)
              annotations[conversationId] = {
                question,
                annotations: conversationAnnotations,
              };
          });

          return {
            syntheticUserName,
            annotations,
          };
        })
        // Filter out interviews without annotations
        .filter((interview) => !!Object.keys(interview.annotations).length)
    );
  }
);
