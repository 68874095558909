import { faBookmark as faBookmarkRegular } from "@fortawesome/free-regular-svg-icons";
import { faBookmark as faBookmarkSolid } from "@fortawesome/free-solid-svg-icons";
import { App as AntApp, Button, Tooltip } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { getAnalytics } from "logic/analytics/analytics";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { bookmarkStudy, unbookmarkStudy } from "store/modules/study/actions";
import { useStudyContainerContext } from "../../contexts/insights-panel";

export function SaveStudyButton() {
  const dispatch = useAppDispatch();
  const summary = useAppSelector((state) => state.summary);
  const study = useAppSelector((state) => state.study);

  const { currentSummaryIndex } = useStudyContainerContext();

  const currentSummary = useMemo(
    () => summary.data && summary.data[currentSummaryIndex],
    [summary, currentSummaryIndex]
  );

  const analytics = getAnalytics();
  const disabled = useMemo(
    () => !currentSummary?.summary.length || summary.loading,
    [summary, currentSummary]
  );

  const { message } = AntApp.useApp();
  const [submitting, setSubmitting] = useState(false);

  const isBookmarked = useMemo(() => {
    // TODO: improve
    const isSameStudy = currentSummary?.studyId === study.data?.id;
    return isSameStudy ? study.data?.bookmarked : false;
  }, [study.data?.bookmarked, currentSummary?.studyId, study.data?.id]);

  const handleClick = useCallback(() => {
    if (!currentSummary?.studyId) return;
    const { studyId } = currentSummary;

    if (isBookmarked) {
      // TODO: check with BE
      analytics.track("workspace:study:unbookmark", { study_id: studyId });
      dispatch(unbookmarkStudy({ studyId }));
    } else {
      // TODO: check with BE
      analytics.track("workspace:study:bookmark", { study_id: studyId });
      dispatch(bookmarkStudy({ studyId }));
    }
    setSubmitting(true);
  }, [dispatch, currentSummary, analytics, isBookmarked]);

  useEffect(() => {
    if (submitting && !study?.loading) {
      setSubmitting(false);
      message.open({
        type: "success",
        content: `Study ${isBookmarked ? "unbookmarked" : "bookmarked"} successfully!`,
        duration: 3,
      });
    }
  }, [study, submitting, isBookmarked, message]);

  return (
    <>
      <Tooltip
        placement="bottomLeft"
        title={
          disabled
            ? "You need to generate user interviews and a summary before you can share your study"
            : ""
        }
      >
        <Button
          disabled={disabled}
          loading={submitting}
          onClick={handleClick}
          title="Save study"
          className="btn btn-link link-body"
          style={{ marginRight: 12 }}
          icon={<AntIcon icon={isBookmarked ? faBookmarkSolid : faBookmarkRegular} />}
        >
          {isBookmarked ? "Unbookmark study" : "Bookmark study"}
        </Button>
      </Tooltip>
    </>
  );
}
