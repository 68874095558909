import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Space, Typography } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import React, { useMemo } from "react";

export type HeaderCardProps = {
  text: string;
  cardType: "youtube" | "website";
};

export const HeaderCard: React.FC<HeaderCardProps> = ({ text, cardType }) => {
  const iconProps = useMemo(() => {
    switch (cardType) {
      case "youtube":
        return {
          icon: faYoutube,
          color: "red",
        };
      case "website":
        return {
          icon: faArrowRight,
        };
    }
  }, [cardType]);

  return (
    <Card bordered={false} style={{ cursor: "pointer" }}>
      <Space align="center">
        <Typography.Paragraph style={{ marginBottom: 0 }}>{text}</Typography.Paragraph>
        <AntIcon
          component={() => <FontAwesomeIcon icon={iconProps.icon} color={iconProps.color} />}
        />
      </Space>
    </Card>
  );
};
