import { faComment, faCopy, faEllipsis, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { Button, Dropdown, Space, Tooltip } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { useToken } from "components/ui-kit/core/token";
import { getAnalytics } from "logic/analytics/analytics";
import { useGetText } from "logic/internals/i18n/get-text";
import React, { RefObject, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { regenerateUserInterview } from "store/modules/user-interviews/actions";
import { FOLLOW_UP_ROUTES } from "templates/user-interviews/follow-up/follow-up-routes";
import { USER_INTERVIEW_OPEN_CHAT_BUTTON_CLASS_NAME } from "./user-interview-content";
import { WorkspaceMode } from "store/modules/workspaces/types";
import { useStudyContainerContext } from "templates/index/workspace-page/contexts/insights-panel";

type UserInterviewActionsProps = {
  userInterviewText: string;
  userInterviewId: string;
  index: number | null;
  listRef: RefObject<HTMLDivElement>;
  followUpId: string;
};

export const UserInterviewActions: React.FC<UserInterviewActionsProps> = ({
  userInterviewText,
  userInterviewId,
  index,
  listRef,
  followUpId,
}) => {
  const token = useToken();
  const dispatch = useAppDispatch();
  const workspaceMode = useAppSelector((state) => state.workspaces.data.workspaceMode);
  const userInterviews = useAppSelector((state) => state.userInterviews);
  const { viewByTopic, setViewByTopic, setCurrentPage } = useStudyContainerContext();
  const analytics = getAnalytics();

  const getText = useGetText();

  const [copyCheckmark, setCopyCheckmark] = useState(false);
  const copyCheckmarkTimeoutRef = useRef<undefined | number>();

  const allInterviewsReady = useMemo(() => {
    const allInterviews = Object.values(userInterviews.data || {});

    return (
      !userInterviews.loading &&
      !!allInterviews.length &&
      allInterviews.every((userInterviewState) => !userInterviewState.loading)
    );
  }, [userInterviews]);

  const dropdownMenuItems = useMemo(() => {
    const isSharedUrl = workspaceMode === WorkspaceMode.SharedUrl;
    const isSavedSummary = workspaceMode === WorkspaceMode.SavedStudy;

    const openOnFollowUpNewPage: ItemType = {
      key: "open-follow-up-on-new-page",
      disabled: isSharedUrl || isSavedSummary,
      label: "Open follow-up on new page",
      icon: <AntIcon icon={faComment} style={{ marginRight: token.margin }} />,
      onClick: (e) => {
        analytics.track("workspace:study:interviews:open-follow-up", {
          user_interview_id: userInterviewId,
        });
        window.open(FOLLOW_UP_ROUTES.getHref(followUpId), "_blank");
        e.domEvent.stopPropagation();
      },
    };

    const copyAllAnswers: ItemType = {
      key: "copy-all-answers",
      label: "Copy all answers",
      icon: (
        <AntIcon
          style={{
            marginRight: token.margin,
            color: copyCheckmark ? token.colorSuccessText : undefined,
          }}
          icon={faCopy}
        />
      ),
      onClick: (e) => {
        e.domEvent.stopPropagation();
        navigator.clipboard.writeText(userInterviewText);
        analytics.track("workspace:study:interviews:copy-interview", {
          user_interview_id: userInterviewId,
        });

        copyCheckmarkTimeoutRef.current && window.clearTimeout(copyCheckmarkTimeoutRef.current);

        setCopyCheckmark(true);

        copyCheckmarkTimeoutRef.current = window.setTimeout(() => {
          setCopyCheckmark(false);
        }, 3000);
      },
    };

    const regenerateFullInterview: ItemType = {
      key: "regenerate-full-interview",
      disabled: isSharedUrl || isSavedSummary,
      label: "Regenerate full interview",
      icon: <AntIcon icon={faRefresh} style={{ marginRight: token.margin }} />,
      onClick: (e) => {
        e.domEvent.stopPropagation();
        analytics.track("workspace:study:interviews:regenerate-full-interview", {
          user_interview_id: userInterviewId,
        });
        dispatch(regenerateUserInterview({ userInterviewId }));
      },
    };

    const copyFullInterview: ItemType = {
      key: "copy-full-interview",
      label: "Copy full interview",
      icon: (
        <AntIcon
          style={{
            color: copyCheckmark ? token.colorSuccessText : undefined,
            marginRight: token.margin,
          }}
          icon={faCopy}
        />
      ),
      onClick: (e) => {
        e.domEvent.stopPropagation();
        navigator.clipboard.writeText(userInterviewText);
        analytics.track("workspace:user-interview:copied-text", {
          text: userInterviewText,
        });

        if (copyCheckmarkTimeoutRef.current) {
          window.clearTimeout(copyCheckmarkTimeoutRef.current);
        }

        setCopyCheckmark(true);

        copyCheckmarkTimeoutRef.current = window.setTimeout(() => {
          setCopyCheckmark(false);
        }, 3000);
      },
    };

    return viewByTopic
      ? [openOnFollowUpNewPage, copyAllAnswers]
      : [openOnFollowUpNewPage, regenerateFullInterview, copyFullInterview];
  }, [
    viewByTopic,
    userInterviewText,
    userInterviewId,
    followUpId,
    copyCheckmark,
    token,
    workspaceMode,
    dispatch,
    analytics,
  ]);

  const scrollToBottom = () => {
    if (!viewByTopic) {
      if (listRef.current) {
        listRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    } else {
      if (index !== null) {
        setViewByTopic(false);
        setCurrentPage(index);

        if (listRef.current) {
          listRef.current?.scrollIntoView();
          setTimeout(() => {
            if (listRef.current) {
              listRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
              });
            }
          }, 300);
        }
      }
    }
  };

  return (
    <>
      <Space>
        <Tooltip
          placement="topLeft"
          title={!allInterviewsReady ? getText("page.generic.disabledWhileLoading") : ""}
        >
          <Button
            disabled={!allInterviewsReady}
            className={USER_INTERVIEW_OPEN_CHAT_BUTTON_CLASS_NAME}
            icon={<AntIcon icon={faComment} />}
            onClick={() => {
              analytics.track("workspace:study:interviews:follow-up", {
                user_interview_id: userInterviewId,
              });
              scrollToBottom();
            }}
          >
            Ask Questions
          </Button>
        </Tooltip>
        <Dropdown
          trigger={["click"]}
          placement="bottomRight"
          disabled={!allInterviewsReady}
          menu={{
            items: dropdownMenuItems,
          }}
        >
          <Tooltip
            placement="topLeft"
            title={!allInterviewsReady ? getText("page.generic.disabledWhileLoading") : ""}
          >
            <Button disabled={!allInterviewsReady} icon={<AntIcon icon={faEllipsis} />}></Button>
          </Tooltip>
        </Dropdown>
      </Space>
    </>
  );
};
