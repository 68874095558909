import { Button, Tooltip } from "antd";
import { getAnalytics } from "logic/analytics/analytics";
import useDebouncedCallback from "logic/hooks/use-debounce";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { CreateReportModal } from "../create-report-modal";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { generateSummary } from "store/modules/summaries/actions";
import { InsightsPanelTabKeys, useStudyContainerContext } from "../../contexts/insights-panel";

type CreateReportButtonProps = {
  isVisible: boolean;
};

export const CREATE_REPORT_HTML_ID = "user-interviews-step-create-report";

export const CreateReportButton: React.FC<CreateReportButtonProps> = ({ isVisible }) => {
  const dispatch = useAppDispatch();
  const userInterviews = useAppSelector((state) => state.userInterviews);

  const analytics = getAnalytics();
  const [showTooltip, setShowTooltip] = useState<boolean | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);

  const { setInsightsPanelTab } = useStudyContainerContext();

  // FEATURE FLAG
  const isReportFocusEnabled = useFeatureFlagEnabled("report-focus");

  const allInterviewsAvailable = useMemo(() => {
    const allInterviews = Object.values(userInterviews.data || {});

    return (
      !!allInterviews &&
      !userInterviews.loading &&
      !allInterviews.some((interview) => interview.loading) &&
      !allInterviews.some((interview) => interview.error)
    );
  }, [userInterviews]);

  const handleClick = useDebouncedCallback(() => {
    if (isReportFocusEnabled) {
      analytics.track("workspace:study:open-generate-report-modal");
      setShowModal(true);
    } else {
      analytics.track("workspace:study:create-report");

      setInsightsPanelTab(InsightsPanelTabKeys.SUMMARY);
      dispatch(generateSummary({ summarizationFocus: undefined }));
    }
  });

  useEffect(() => {
    // Only triggger tooltip handling if generate button is visible (insight panel open)
    if (!isVisible) return setShowTooltip(undefined);
    // Open tooltip when all interviews are available (using undifined state value to make sure it only opens once)
    // Using 500 ms timeout so we only show the tooltip after the panel opening animation is finished
    if (allInterviewsAvailable && showTooltip === undefined)
      setTimeout(() => setShowTooltip(true), 500);
    // Close tooltip after 5 seconds
    if (allInterviewsAvailable && showTooltip) setTimeout(() => setShowTooltip(false), 5000);
  }, [allInterviewsAvailable, showTooltip, isVisible]);

  return (
    <>
      {isReportFocusEnabled && <CreateReportModal visible={showModal} setVisible={setShowModal} />}
      <div style={{ backgroundColor: "white", borderRadius: "6px" }}>
        <Tooltip
          title={isVisible && showTooltip ? "Interviews finished. Ready to generate report." : ""}
          placement="bottomRight"
          open={isVisible && showTooltip}
        >
          <Button
            onClick={handleClick}
            id={CREATE_REPORT_HTML_ID}
            type="primary"
            disabled={!allInterviewsAvailable}
          >
            Create report
          </Button>
        </Tooltip>
      </div>
    </>
  );
};
