import { Card, Col, Row, Typography } from "antd";
import { TextWithNewLines } from "components/ui-kit/components/text-with-new-lines";
import { useToken } from "components/ui-kit/core/token";
import { useGetText } from "logic/internals/i18n/get-text";
import { CSSProperties, useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { ProjectSettings } from "store/modules/projects/types";

export function SnapshotCard(props: { style: CSSProperties }) {
  const token = useToken();
  const getText = useGetText();

  const projectSettings = useAppSelector((state) => state.projects.data.projectSettings);
  const audiencesData = useAppSelector((state) => state.audiences.data);
  const problemsData = useAppSelector((state) => state.problems.data);
  const solutionData = useAppSelector((state) => state.solutions.data);
  const researchGoal = useAppSelector((state) => state.researchGoal.data);
  const customScript = useAppSelector((state) => state.customScript.data?.customScript);

  const selectedAudiences = useMemo(() => {
    return (
      audiencesData.selectedAudiences ||
      audiencesData?.audiences?.filter((audience) =>
        audiencesData?.selectedAudiencesIds?.includes(audience.id)
      )
    );
  }, [audiencesData]);

  const selectedProblems = useMemo(() => {
    return (
      problemsData.selectedProblems ||
      problemsData?.problems?.filter((problem) =>
        problemsData?.selectedProblemsIds?.includes(problem.id)
      )
    );
  }, [problemsData]);

  const selectedSolution = useMemo(() => {
    return (
      solutionData.selectedSolutions ||
      solutionData?.solutions?.filter((solution) =>
        solutionData?.selectedSolutionId?.includes(solution.id)
      )
    );
  }, [solutionData]);

  const columnsCount = useMemo(() => {
    let count = 0;
    if (selectedAudiences?.length) count++;
    if (selectedProblems?.length) count++;
    if (selectedSolution?.length) count++;
    if (researchGoal) count++;
    if (customScript) count++;

    return count;
  }, [selectedAudiences, selectedProblems, selectedSolution, researchGoal, customScript]);

  return (
    <Card
      style={{ maxWidth: "978px", alignContent: "center", margin: "auto", ...props.style }}
      title="Snapshot"
    >
      <Row gutter={token.margin}>
        {/* Audiences */}
        <Col span={24 / columnsCount}>
          <Typography.Paragraph strong>Audience</Typography.Paragraph>
          {selectedAudiences?.map((audience, index) => (
            <Typography.Paragraph key={index}>
              <div
                style={{
                  marginLeft: "3px",
                  paddingLeft: token.padding,
                  borderLeft: "1px solid #C3C3C3",
                }}
              >
                <TextWithNewLines>{audience.description}</TextWithNewLines>
              </div>
            </Typography.Paragraph>
          ))}
        </Col>

        {/* Problems */}
        {!!selectedProblems?.length && (
          <Col span={24 / columnsCount}>
            <Typography.Paragraph strong>Pains, needs and/or goals</Typography.Paragraph>
            <Typography.Paragraph>
              <div
                style={{
                  marginLeft: "3px",
                  paddingLeft: token.padding,
                  borderLeft: "1px solid #C3C3C3",
                }}
              >
                <TextWithNewLines>
                  {selectedProblems.length === 0
                    ? getText("page.insights.noinputgiven")
                    : selectedProblems.map((p) => p.description).join("\n")}
                </TextWithNewLines>
              </div>
            </Typography.Paragraph>
          </Col>
        )}

        {/* Solutions */}
        {!!selectedSolution && !!selectedSolution[0] && (
          <Col span={24 / columnsCount}>
            <Typography.Paragraph strong>Solution</Typography.Paragraph>
            <Typography.Paragraph>
              <div
                style={{
                  marginLeft: "3px",
                  paddingLeft: token.padding,
                  borderLeft: "1px solid #C3C3C3",
                }}
              >
                <TextWithNewLines>
                  {selectedSolution[0]?.description === "" ||
                  selectedSolution[0]?.description === undefined
                    ? getText("page.insights.noinputgiven")
                    : selectedSolution[0]?.description}
                </TextWithNewLines>
              </div>
            </Typography.Paragraph>
          </Col>
        )}

        {/* Research Goal */}
        {!!researchGoal && projectSettings === ProjectSettings.DynamicInterviewMode && (
          <Col span={24 / columnsCount}>
            <Typography.Paragraph strong>Research Goal</Typography.Paragraph>
            <Typography.Paragraph>
              <div
                style={{
                  marginLeft: "3px",
                  paddingLeft: token.padding,
                  borderLeft: "1px solid #C3C3C3",
                }}
              >
                <TextWithNewLines>
                  {researchGoal.description === "" || researchGoal?.description === undefined
                    ? getText("page.insights.noinputgiven")
                    : researchGoal?.description}
                </TextWithNewLines>
              </div>
            </Typography.Paragraph>
          </Col>
        )}

        {/* Custom Script */}
        {!!customScript && (
          <Col span={24 / columnsCount}>
            <Typography.Paragraph strong>Custom Script</Typography.Paragraph>
            <Typography.Paragraph>
              <div
                style={{
                  marginLeft: "3px",
                  paddingLeft: token.padding,
                  borderLeft: "1px solid #C3C3C3",
                }}
              >
                <TextWithNewLines>
                  {customScript === "" || customScript === undefined
                    ? getText("page.insights.noinputgiven")
                    : customScript}
                </TextWithNewLines>
              </div>
            </Typography.Paragraph>
          </Col>
        )}
      </Row>
    </Card>
  );
}
