import { App as AntApp } from "antd";
import { useEffect } from "react";

const MESSAGE_KEY = "done-generating-user-interviews-message";

export function DoneGeneratingUserInterviewsMessage(props: { callId: string }) {
  const { message } = AntApp.useApp();

  useEffect(() => {
    message.success({
      content: "Interviews are ready",
      duration: 3,
      key: MESSAGE_KEY,
    });
  }, [message, props.callId]);

  return <></>;
}
