import React, { useMemo } from "react";
import { NewUIHome } from "./home";
import { ProjectSettings } from "store/modules/projects/types";
import { InputsSelection } from "./inputs-selection";
import { useRouter } from "next/router";
import { useAppSelector } from "store/hooks";

export enum InterviewMode {
  DYNAMIC_INTERVIEW = "Dynamic Interview",
  MESSAGE_TESTING = "Message Testing",
  PROBLEM_EXPLORATION = "Problem Exploration",
  CUSTOM_SCRIPT = "Custom Script",
  CONCEPT_TESTING = "Concept Testing",
}

export const NewUI: React.FC = () => {
  const router = useRouter();
  const studyStrategy = useAppSelector((state) => state.study.data?.studyStrategy);
  const interviewMode = useAppSelector((state) => state.projects.data.projectSettings);
  const { studyId } = router.query;

  const studyType = useMemo(() => {
    if (!studyId || !studyStrategy) return;
    switch (studyStrategy) {
      case InterviewMode.DYNAMIC_INTERVIEW:
        return ProjectSettings.DynamicInterviewMode;
      case InterviewMode.PROBLEM_EXPLORATION:
        return ProjectSettings.ProblemExplorationMode;
      case InterviewMode.CUSTOM_SCRIPT:
        return ProjectSettings.CustomScriptMode;
      case InterviewMode.CONCEPT_TESTING:
        return ProjectSettings.SolutionFeedbackMode;
      case InterviewMode.MESSAGE_TESTING:
        return ProjectSettings.SolutionFeedbackMode;
    }
  }, [studyStrategy, studyId]);

  return interviewMode ? (
    <InputsSelection interviewMode={studyType || interviewMode} isReadOnly={!!studyId} />
  ) : (
    <NewUIHome />
  );
};
