import { template } from "lodash";
import { useCallback } from "react";

const TEXT = {
  "test": "test",
  "testFn": template("hello <%= arg %>!"),
  "page.walkthrough.title": "Welcome to Synthetic Users!",
  "page.walkthrough.subtitle":
    "Our goal is to leverage the power of artificial intelligence to generate realistic synthetic personas and evaluate the desirability of your ideas in a few minutes.",
  "page.walkthrough.selectYourGoal": "Select your goal:",
  "page.walkthrough.testAnIdea": "Test an idea",
  "page.walkthrough.exploreProblemSpace": "Explore problem space",
  "page.walkthrough.whatYouWillDefine": "What you will be defining:",
  "page.walkthrough.audience": "Audience",
  "page.walkthrough.audience.description": "Who are you building for?",
  "page.walkthrough.problems": "Pains, needs or goals",
  "page.walkthrough.problems.description": "What are you solving?",
  "page.walkthrough.solution": "Solutions",
  "page.walkthrough.solution.description": "How are you solving it?",
  "page.walkthrough.nextButton": "Let's get started!",
  "page.walkthrough.skipWalkthroughButton": "Skip walkthrough",
  "page.walkthrough.yourChecklist": "Your checklist:",
  //
  "page.walkthrough.common.step.type": "Type",
  "page.walkthrough.common.step.examples": "Examples:",
  "page.walkthrough.common.step.skip": "Skip for now",
  "page.walkthrough.common.step.next": "Save",
  "page.walkthrough.common.step.submit": "Let's go",
  //
  "page.walkthrough.audience.step.title": "Who are you building for?",
  "page.walkthrough.audience.step.description":
    "Describe your target audience: you can tell us as much or as little as you'd like.",
  "page.walkthrough.audience.step.example1":
    "34 Year old worker in the fintech industry, married, with two dogs and a love of rugby.",
  "page.walkthrough.audience.step.example2": "Couples in a long distance relationship.",
  "page.walkthrough.audience.step.example3": "An overwhelmed product designer.",
  //
  "page.walkthrough.problems.step.title": "What pains, needs and/or goals are you trying to solve?",
  "page.walkthrough.problems.step.description":
    "Tell us a bit more about the things you have identified and would like to solve.\nUse a line for each pain, need or goal.",
  "page.walkthrough.problems.step.example1": "Struggling to remember to water indoor plants.",
  "page.walkthrough.problems.step.example2": "Dwindling emotional connection.",
  "page.walkthrough.problems.step.example3": "Not having budget to run user interviews.",
  //
  "page.walkthrough.solution.step.title": "How do you think these can be solved?",
  "page.walkthrough.solution.step.description":
    "If you have an idea of how these problems could be solved, use the input below to describe it.",
  "page.walkthrough.solution.step.example1":
    "A sensor-equipped planter that detects soil moisture levels and waters plants as needed.",
  "page.walkthrough.solution.step.example2":
    "A wearable device designed to keep partners connected.",
  "page.walkthrough.solution.step.example3":
    "Synthetic personas powered by LLMs that provide feedback on product or features.",

  "page.workspace:tour:step:0:title": "Almost there!",
  "page.workspace:tour:step:0:description":
    "Your answers have been sorted in the respective columns! Next step: select how many interviews you'd like to run and click “Generate”!",

  "page.workspace.audience": "Audience",
  "page.workspace.audiences": "Audiences",
  "page.workspace.problem": "Pain, need and/or goal",
  "page.workspace.problems": "Pains, needs and/or goals",
  "page.workspace.solution": "Solution",
  "page.workspace.solutions": "Solutions",
  "page.workspace.alert.pendinginvites.title": "You have pending workspace invites.",
  "page.workspace.alert.pendinginvites.button": "Check invites",

  "page.checkyourscript.cover.title": "Check your interview script",
  "page.checkyourscript.cover.subtitle":
    "Your AI-powered interview script checker! We use the latest in machine learning technology to analyse your script and provide real-time feedback on areas that need improvement. Save time and ensure a successful interview with our easy-to-use and intuitive platform.",
  "page.checkyourscript.cover.button": "Click here to start",
  "page.checkyourscript.newscript.nav.title": "Check your script",
  "page.checkyourscript.newscript.nav.back": "Exit",
  "page.checkyourscript.newscript.exit.confirmation.title":
    "Are you sure you want to exist this study?",
  "page.checkyourscript.newscript.exit.confirmation.subtitle": "All information will be lost.",
  "page.checkyourscript.newscript.exit.confirmation.cancel": "Cancel",
  "page.checkyourscript.newscript.exit.confirmation.confirm": "Exit",
  "page.checkyourscript.newscript.title": "Select the goal(s) of your study",
  "page.checkyourscript.newscript.subtitle":
    "This will help us identify if your questions are aligned with your goals in order to provide relevant data.",
  "page.checkyourscript.newscript.title2": "Share your script with us",
  "page.checkyourscript.newscript.subtitle2": "Copy and paste your script into the text box below.",
  "page.checkyourscript.newscript.script.placeholder": "Paste your script here",
  "page.checkyourscript.newscript.title3": "Describe your audience",
  "page.checkyourscript.newscript.subtitle3":
    "This will help us identify if your questions and the language used is clear for your target audience to understand.",
  "page.checkyourscript.newscript.audience.placeholder":
    "Specify the target audience or demografic group",
  "page.checkyourscript.newscript.audience.example": "Examples:",
  "page.checkyourscript.newscript.audience.example1":
    "34 Year old worker in the fintech industry, married, with two dogs and a love of rugby.",
  "page.checkyourscript.newscript.audience.example2": "Couples in a long distance relationship.",
  "page.checkyourscript.newscript.audience.example3": "An overwhelmed product designer.",
  "page.checkyourscript.newscript.title4": "Share the duration of the interview",
  "page.checkyourscript.newscript.subtitle4":
    "What is the planned time for each interview? We will verify if the duration of your interview aligns ith the time allocated in your schedule.",
  "page.checkyourscript.newscript.title5": "Describe your topic",
  "page.checkyourscript.newscript.subtitle5":
    "Examples: parenting and purchases, B2B sales challenges or Long Distance Relationships pains.",
  "page.checkyourscript.newscript.goal.placeholder": "Specify the topic you're exploring",
  "page.checkyourscript.newscript.termsandconditions":
    "I agree to share this information with Check your script.",
  "page.checkyourscript.newscript.feedbackbutton": "Give me feedback",
  "page.checkyourscript.newscript.customgoal.button": "Can't find your goal? Add it here",
  "page.checkyourscript.newscript.customgoal.popup.title": "What is the goal of your study?",
  "page.checkyourscript.newscript.customgoal.popup.placeholder": "Describe your goal",
  "page.checkyourscript.newscript.customgoal.popup.savebutton": "Save",

  "page.checkyourscript.feedback.previewwithoutwarnings": "Preview without warnings",
  "page.checkyourscript.feedback.approveallsuggestions": "Approve all suggestions",
  "page.checkyourscript.feedback.finish": "Finish",
  "page.checkyourscript.feedback.title": "Feedback",
  "page.checkyourscript.feedback.questions.title": "In-depth feedback and suggestions",
  "page.checkyourscript.feedback.overall.title": "Overall feedback",
  "page.checkyourscript.feedback.card.keepasis": "Keep as is",
  "page.checkyourscript.feedback.card.approve": "Approve",
  "page.checkyourscript.feedback.card.remove": "Remove",
  "page.checkyourscript.feedback.card.accept": "Ok",
  "page.checkyourscript.feedback.card.undo": "Undo",
  "page.checkyourscript.feedback.card.keep": "Keep",
  "page.checkyourscript.feedback.textreviewed": "This text has been reviewed.",
  "page.checkyourscript.feedback.textremoved": "This question will be removed.",
  "page.checkyourscript.feedback.editbutton": "Edit your script",
  "page.checkyourscript.feedback.edit.confirmation.title":
    "Are you sure you want to edit your script?",
  "page.checkyourscript.feedback.edit.confirmation.subtitle":
    "Changes made to your script will be lost.",
  "page.checkyourscript.feedback.edit.confirmation.cancel": "Cancel",
  "page.checkyourscript.feedback.edit.confirmation.confirm": "Edit",
  "page.checkyourscript.download.title":
    "Bravo! Your interview script is now a perfect fit for your research goals!",
  "page.checkyourscript.download.subtitle": "Choose how you want to get it:",
  "page.checkyourscript.download.downloadbutton": "Download script",
  "page.checkyourscript.download.clipboardbutton": "Copy to clipboard",
  "page.checkyourscript.download.goToInterviewbutton": "Check another script",
  "page.checkyourscript.download.tip":
    "Before you goo rock those interviews: check out the other reasearch tools up our sleeve",
  "page.checkyourscript.error.request":
    "Oops! Your feedback has exceeded our word limit. Check Your Script needs to keep things concise to offer the best insight. Please trim down your script and try again. Remember, sometimes less is more!",
  "page.checkyourscript.feedback.card.potencialrephasing": "Potencial rephrasing:",
  "page.insights.noinputgiven": "No input given",
  "page.generic.disabledWhileLoading": "Disabled while loading interviews",
  "page.workspace.signup.title": "You have access to these workspaces",
  "page.plans.price.enterprise.title": "Let's talk",
  "page.plans.price.usage.exceeds.tooltip":
    "Your current workspace exceeds this plan's seats limit. You can either create another workspace or upgrade to a higher plan.",
};

export function useGetText() {
  return useCallback(<K extends keyof typeof TEXT>(key: K, args?: { [key: string]: string }) => {
    const text = TEXT[key];

    if (typeof text === "string") {
      return text;
    } else {
      return text(args);
    }
  }, []);
}
