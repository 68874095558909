import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Card, Input, Typography } from "antd";
import { ErrorFeedback } from "components/shared/error-feedback";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { Container } from "components/ui-kit/components/container";
import { Spinner } from "components/ui-kit/components/spinner";
import { useToken } from "components/ui-kit/core/token";
import { getAnalytics } from "logic/analytics/analytics";
import { isTransportFailure } from "logic/internals/transports/transported-data/is-transport-failure";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactMarkdown from "react-markdown";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  addUserInterviewConversation,
  connectUserInterviewConversationsWS,
  getUserInterviewConversations,
} from "store/modules/user-interview-conversations/actions";
import { z } from "zod";

type UserInterviewFollowUpContainerProps = {
  userInterviewId: string;
  readonly?: boolean;
};

export const UserInterviewFollowUpContainer: React.FC<UserInterviewFollowUpContainerProps> = ({
  userInterviewId,
  readonly = false,
}) => {
  const dispatch = useAppDispatch();
  const userInterviewConversations = useAppSelector((state) => state.userInterviewConversations);
  const userInterviews = useAppSelector((state) => state.userInterviews);
  const token = useToken();
  const analytics = getAnalytics();

  const allInterviewsAvailable = useMemo(() => {
    const allInterviews = Object.values(userInterviews.data || {});
    return (
      !!allInterviews &&
      !userInterviews.loading &&
      !allInterviews.some((interview) => interview.loading) &&
      !allInterviews.some((interview) => interview.error)
    );
  }, [userInterviews]);

  const sortedUserInterviewConversations = useMemo(() => {
    // spread the array to avoid direct state mutation
    const currInterviews = [
      ...(userInterviewConversations.data ? userInterviewConversations.data : []),
    ];

    return currInterviews?.sort(
      (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
  }, [userInterviewConversations.data]);

  const form = useForm<{ text: string }>({
    resolver: zodResolver(
      z.object({
        text: z.string().min(1),
      })
    ),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (allInterviewsAvailable) {
      dispatch(getUserInterviewConversations({ interviewId: userInterviewId }));
    }
  }, [userInterviewId, dispatch, allInterviewsAvailable]);

  return (
    <>
      {/* Error state */}
      {isTransportFailure(userInterviewConversations.error) && (
        <ErrorFeedback
          error={userInterviewConversations.error}
          style={{ marginBottom: token.margin }}
        />
      )}

      {/* Loading state */}
      {userInterviewConversations.loading && (
        <Spinner style={{ marginBottom: token.margin, marginTop: token.margin }} size="large" />
      )}

      {/* Conversations list */}
      <div
        style={{
          flex: "1 1 auto",
          marginBottom: readonly ? token.margin : "initial",
        }}
      >
        {!!sortedUserInterviewConversations &&
          sortedUserInterviewConversations.map(
            (conversation, index) =>
              index % 2 == 0 && (
                <Card
                  key={index}
                  style={{
                    marginTop: token.margin,
                    borderBlockColor: "transparent",
                    borderLeftColor: "transparent",
                    borderRightColor: "transparent",
                    backgroundColor: token.colorFillSecondary,
                  }}
                >
                  {/* even index entries are the questions - To be refactored after BE endpoint logic changes */}
                  <Typography.Title level={4}>{conversation.message}</Typography.Title>
                  {!!userInterviewConversations.data &&
                    !!userInterviewConversations.data[index + 1]?.message && (
                      <ReactMarkdown>
                        {userInterviewConversations.data[index + 1]?.message || ""}
                      </ReactMarkdown>
                    )}
                </Card>
              )
          )}
      </div>

      {/* New Question Input */}
      {!readonly && (
        <div>
          <form
            style={{
              position: "sticky",
              bottom: 0,
            }}
            onSubmit={form.handleSubmit((formValue) => {
              analytics.track("workspace:study:interviews:follow-up:chat", {
                user_interview_id: userInterviewId,
              });

              form.reset();

              setTimeout(() => {
                window.scrollBy({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                });
              }, 0);

              // add the new question to state
              dispatch(addUserInterviewConversation(userInterviewId, formValue.text));
              // connect to the WS
              dispatch(connectUserInterviewConversationsWS(userInterviewId, formValue.text));
            })}
          >
            <Card
              hidden={!allInterviewsAvailable}
              style={{
                marginTop: token.margin,
                borderBlockColor: "transparent",
                borderLeftColor: "transparent",
                borderRightColor: "transparent",
              }}
            >
              <Container style={{ maxWidth: "800px" }}>
                <Controller
                  control={form.control}
                  name="text"
                  render={({ field: { value, onChange } }) => (
                    <Input
                      autoComplete="off"
                      id="follow-up-template-chat-input"
                      disabled={userInterviewConversations.loading || !allInterviewsAvailable}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onPaste={(e) => {
                        e.preventDefault();
                        const pastedData = e.clipboardData.getData("text");
                        onChange(pastedData);
                      }}
                      suffix={
                        <Button
                          htmlType="submit"
                          disabled={
                            !form.formState.isValid ||
                            !Object.keys(form.formState.dirtyFields).length ||
                            userInterviewConversations.loading
                          }
                          icon={<AntIcon icon={faPaperPlane} />}
                          type="text"
                        />
                      }
                    />
                  )}
                />
              </Container>
            </Card>
          </form>
        </div>
      )}
    </>
  );
};
