// React
import { useContext, createContext, Dispatch, SetStateAction } from "react";
// Types

export type DrawerContextProps = {
  drawerOpen: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
};

const DrawerContextDefaults: DrawerContextProps = {
  drawerOpen: false,
  setDrawerOpen: () => {
    throw new Error("to use this context, please wrap your component with DrawerContextProvider");
  },
};

// Context
export const DrawerContext = createContext<DrawerContextProps>(DrawerContextDefaults);

// Hook
export const useDrawerContext = (): DrawerContextProps => useContext(DrawerContext);
