import { LoginModalUI } from "templates/index/login/modal-ui";
import { useAuthState } from "logic/auth/auth-state";
import { throwError } from "logic/internals/utils/throw-error";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";

type ContextValue = {
  showSignupRequiredAlert: () => void;
};
const SignupRequiredContext = createContext<ContextValue | null>(null);

export function SignupRequiredAlert(props: { isMobile?: boolean; children: ReactNode }) {
  const auth = useAuthState();
  const user = auth.session.data?.user;

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (user && !user.isAnonymous) {
      setShowAlert(false);
    }
  }, [user]);

  return (
    <SignupRequiredContext.Provider value={{ showSignupRequiredAlert: () => setShowAlert(true) }}>
      <LoginModalUI
        open={showAlert}
        onCancel={() => setShowAlert(false)}
        title="Sign up to access this feature"
        description="You've exhausted your complimentary interviews. Sign up to run more studies. It only takes a few seconds!"
      />
      {props.children}
    </SignupRequiredContext.Provider>
  );
}

export function useSignupRequiredAlert() {
  return useContext(SignupRequiredContext) || throwError();
}
