import { ErrorFeedback } from "components/shared/error-feedback";
import { useToken } from "components/ui-kit/core/token";
import {
  isSubscriptionRelatedTransportFailure,
  isTransportFailure,
} from "logic/internals/transports/transported-data/is-transport-failure";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { generateUserInterviews } from "store/modules/user-interviews/actions";
import { StateProps as UserInterviewState } from "store/modules/user-interviews/types";
import { PlanUpgradeModal } from "templates/index/workspace-page/content/plan-upgrade-modal/plan-upgrade-modal";
import { v1 } from "uuid";
import { DoneGeneratingUserInterviewsMessage } from "./components/done-generating-user-interviews-message";
import { GeneratingUserInterviewsMessage } from "./components/generating-user-interviews-message";
import { UserInterviewByTopicContent } from "./components/user-interview-by-topic-content";
import { UserInterviewContent } from "./components/user-interview-content";
import { UserInterviewDisplayOptionsMenu } from "./components/user-interview-display-options-menu";
import { useStudyContainerContext } from "templates/index/workspace-page/contexts/insights-panel";

type UserInterviewsStepProps = {
  listRef: RefObject<HTMLDivElement>;
};

export const UserInterviewsTab: React.FC<UserInterviewsStepProps> = ({ listRef }) => {
  const token = useToken();
  const dispatch = useAppDispatch();

  const projectId = useAppSelector((state) => state.projects.data.project?.id);
  const userInterviews = useAppSelector((state) => state.userInterviews);
  const syntheticUsers = useAppSelector((state) => state.syntheticUsers.data);
  const selectedAudienceIds = useAppSelector((state) => state.audiences.data.selectedAudiencesIds);
  const prevInterviewState = useRef<UserInterviewState>(userInterviews);

  const [showPlanUpgradeModal, setShowPlanUpgradeModal] = useState(true);

  const {
    currentPage,
    totalPages,
    setTotalPages,
    currentTopic,
    totalTopics,
    setTotalTopics,
    viewByTopic,
    currentAudience,
  } = useStudyContainerContext();

  const currentAudienceInterviews = useMemo(() => {
    if (!userInterviews?.data || !selectedAudienceIds) return [];
    return Object.values(userInterviews.data).filter(
      (userInterview) =>
        userInterview?.data?.syntheticUserId === selectedAudienceIds[currentAudience]
    );
  }, [userInterviews, selectedAudienceIds, currentAudience]);

  const currentUserInterview = useMemo(
    () => currentAudienceInterviews[currentPage],
    [currentAudienceInterviews, currentPage]
  );

  const currentTopicText = useMemo(
    () => currentAudienceInterviews?.[0]?.data.topics?.[currentTopic] || "",
    [currentAudienceInterviews, currentTopic]
  );

  const currentTopicInterviews = useMemo(() => {
    return currentAudienceInterviews.filter((interview) =>
      interview.data.userInterviewWithTopics?.some(
        (interviewWithTopics) => interviewWithTopics.topic === currentTopicText
      )
    );
  }, [currentAudienceInterviews, currentTopicText]);

  const isDoneGeneratingUserDetails = useMemo(
    () =>
      !!currentAudienceInterviews.length &&
      currentAudienceInterviews.every(
        (interview) =>
          interview.data.generatedSyntheticUserPersonalityDetails?.personalInformation !== undefined
      ),
    [currentAudienceInterviews]
  );

  const generatedTopicsLength = useMemo(() => {
    const hasGeneratedTopics = currentAudienceInterviews.every(
      (interview) => interview.data.userInterviewWithTopics !== undefined
    );

    return hasGeneratedTopics ? currentAudienceInterviews[0]?.data.topics?.length : undefined;
  }, [currentAudienceInterviews]);

  const generatingInterviews = useMemo(
    () =>
      !!currentAudienceInterviews.length &&
      currentAudienceInterviews.some((interview) => interview.loading),
    [currentAudienceInterviews]
  );

  const isDoneGeneratingAllInterviews = useMemo(() => {
    if (
      !!prevInterviewState.current &&
      !!prevInterviewState.current.loading &&
      !!currentAudienceInterviews.length &&
      currentAudienceInterviews.every((interview) => !interview.loading) &&
      currentAudienceInterviews.every((interview) => !interview.error)
    )
      return v1();
  }, [currentAudienceInterviews]);

  useEffect(() => {
    if (isDoneGeneratingAllInterviews) analytics.track("workspace:study:interviews:ready");
  }, [isDoneGeneratingAllInterviews]);

  useEffect(() => {
    if (prevInterviewState.current !== userInterviews) {
      prevInterviewState.current = userInterviews;
    }
  }, [userInterviews]);

  useEffect(() => {
    if (isDoneGeneratingUserDetails && !totalPages) {
      setTotalPages(currentAudienceInterviews.length - 1);
    }

    if (generatedTopicsLength && !totalTopics) {
      setTotalTopics(generatedTopicsLength - 1);
    }
  }, [
    totalPages,
    setTotalPages,
    totalTopics,
    setTotalTopics,
    currentAudienceInterviews.length,
    isDoneGeneratingUserDetails,
    generatedTopicsLength,
    generatingInterviews,
  ]);

  return (
    <>
      {generatingInterviews && <GeneratingUserInterviewsMessage />}
      {/* TODO: fix */}
      {isDoneGeneratingAllInterviews && (
        <DoneGeneratingUserInterviewsMessage callId={isDoneGeneratingAllInterviews} />
      )}
      {isSubscriptionRelatedTransportFailure(userInterviews.error) && (
        <PlanUpgradeModal
          error={userInterviews.error}
          visible={showPlanUpgradeModal}
          setVisible={setShowPlanUpgradeModal}
        />
      )}
      {isTransportFailure(userInterviews.error) ? (
        <ErrorFeedback
          error={userInterviews.error}
          style={{ marginBottom: token.margin }}
          buttonAction={
            userInterviews.error === TransportFailure.PreparingSyntheticUsersFailed
              ? () => {
                  // TODO: check if
                  if (projectId && syntheticUsers?.length) {
                    dispatch(
                      generateUserInterviews({
                        projectId: projectId,
                        interviews: syntheticUsers?.length,
                      })
                    );
                  }
                }
              : undefined
          }
        />
      ) : (
        <>
          <UserInterviewDisplayOptionsMenu
            listRef={listRef}
            userInterviews={currentAudienceInterviews}
            loading={!!userInterviews.loading}
            disabled={!isDoneGeneratingUserDetails || currentAudienceInterviews.length <= 1}
          />
          {viewByTopic ? (
            <UserInterviewByTopicContent
              listRef={listRef}
              userInterviews={currentTopicInterviews}
              currentTopicText={currentTopicText}
            />
          ) : (
            !!currentUserInterview && (
              <UserInterviewContent
                listRef={listRef}
                userInterview={currentUserInterview}
                followUpId={currentUserInterview.data?.userInterviewId || ""}
              />
            )
          )}
        </>
      )}
    </>
  );
};
