import React, { useCallback, useMemo } from "react";
import { createRoot } from "react-dom/client";
import ReactMarkdown from "react-markdown";
import remarkFlexibleMarkers from "remark-flexible-markers";
import { ElementContent } from "react-markdown/lib/ast-to-react";

export type HighlightedText = {
  id: string;
  content: string;
  startIndex: number;
  endIndex: number;
};

type MarkdownWithHighlightsProps = {
  children: string;
  newHighlightComponent: (
    highlightedText: string,
    startIndex: number,
    endIndex: number,
    onClose: () => void
  ) => React.ReactNode;
  existingHighlightComponent: (highlightedText?: string, id?: string) => React.ReactNode;
  onMouseUp?: () => void;
  highlightedText?: HighlightedText[];
};

const sourcePositionCalculator = (sourcePositionOffset: number, text: string) => {
  let calculatedSourcePosition = sourcePositionOffset;

  // Get the initial text before the source position calculation
  const initialText = text.slice(0, sourcePositionOffset);

  // Regular expression to match '==' followed by a UUID
  const regex = /==[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g;

  // Decrement the source position by 40 for each match (each match represents a highlight)
  initialText.match(regex)?.forEach(() => {
    calculatedSourcePosition -= 40;
  });

  return calculatedSourcePosition;
};

const MarkdownWithHighlights: React.FC<MarkdownWithHighlightsProps> = ({
  children,
  highlightedText,
  newHighlightComponent,
  existingHighlightComponent,
}) => {
  const HIGHLIGHT_MARKERS_LENGTH = 4;
  const UUID_LENGTH = 36;
  const ADDED_CHARACTERS_BY_HIGHLIGHT = HIGHLIGHT_MARKERS_LENGTH + UUID_LENGTH;

  const contentWithAnnotations = useMemo(() => {
    let summedContentWithAnnotations = children;

    highlightedText?.forEach((annotation, i) => {
      // calculate the offset (number of characters added by the previous annotations)
      const offset = ADDED_CHARACTERS_BY_HIGHLIGHT * i;
      // calculate the start index of the annotation
      const start = annotation.startIndex + offset;
      // calculate the end index of the annotation
      const end = annotation.endIndex + offset;
      // get the content of the annotation with the id
      const content = `${annotation.id}${annotation.content.trim()}`;
      // check if the content has a trailing whitespace
      const trailingWhitespace = annotation.content.endsWith(" ") ? " " : "";
      // check if the content has a leading whitespace
      const leadingWhitespace = annotation.content.startsWith(" ") ? " " : "";

      // add the annotation to the content
      summedContentWithAnnotations = `${summedContentWithAnnotations.slice(0, start)}${leadingWhitespace}==${content}==${trailingWhitespace}${summedContentWithAnnotations.slice(end)}`;
    });

    return summedContentWithAnnotations;
  }, [children, highlightedText, ADDED_CHARACTERS_BY_HIGHLIGHT]);

  const handleMouseUp = useCallback(
    (sourcePositionOffset?: number, element?: ElementContent[]) => {
      // get the selected text
      const selectedText = window.getSelection()?.toString();
      // get the selected range
      const range = window.getSelection()?.getRangeAt(0);
      // early return if:
      // - there is no range
      // - there is no selected text
      // - the selected text is empty
      // - the selected text is too short (less than 2 characters)
      // - the sourcePositionOffset is undefined
      // - the paragraphIndex is undefined
      // - the range start container parent node is a strong tag
      // - the range common ancestor container is not a text node
      if (
        !range ||
        !selectedText ||
        selectedText?.trim() === "" ||
        (selectedText?.length && selectedText?.length < 2) ||
        sourcePositionOffset === undefined ||
        range?.startContainer.parentNode?.nodeName === "STRONG" ||
        range.commonAncestorContainer.nodeName !== "#text"
      )
        return;

      // initialize the distance to the start of the paragraph
      let distanceToParagraphStart = range.startOffset || 0;

      // get the previous sibling of the range start container
      let previousSibling = range.startContainer.previousSibling;

      // loop through the previous siblings and add the length of their text content to the distance until the start of the paragraph
      while (previousSibling) {
        if (previousSibling.textContent)
          distanceToParagraphStart += previousSibling.textContent?.length || 0;

        previousSibling = previousSibling.previousSibling;
      }

      // get the computed source position offset (sourcePositionOffset - prevHighlightsCount * 4)
      const computedSourcePositionOffset = sourcePositionCalculator(
        sourcePositionOffset,
        contentWithAnnotations
      );

      // check if the selected text's parent element has a strong tag
      // if it does, add 4 to the offset (to account for the strong tag's length)
      const strongOffset = element?.find((el) => "tagName" in el && el.tagName === "strong")
        ? 4
        : 0;

      // calculate the start position of the selected text
      const start = (computedSourcePositionOffset || 0) + distanceToParagraphStart + strongOffset;
      // calculate the end position of the selected text
      const end = start + (selectedText?.length || 0);

      const isIntersectingHighlights = highlightedText?.some((h) => {
        const endsInside = end >= h.startIndex && end <= h.endIndex;
        const startsInside = range?.startContainer.parentNode?.nodeName === "MARK";
        const isContaining = start <= h.startIndex && end >= h.endIndex;

        return startsInside || endsInside || isContaining;
      });

      if (isIntersectingHighlights) return;

      // create a new span element
      const newNode = document.createElement("span");
      // create a new root
      const root = createRoot(newNode);

      // add the new highlight component to the root
      root.render(newHighlightComponent(selectedText, start, end, () => onClose(range)));

      // replace the range with the new node
      range?.deleteContents();
      range?.insertNode(newNode);
    },
    [contentWithAnnotations, highlightedText, newHighlightComponent]
  );

  const onClose = (range: Range) => {
    //create a new text node
    const newNode = document.createTextNode(range.toString());
    //get the paragraph of the range
    const rangeParagraph =
      range.startContainer.nodeName === "p" ? range.startContainer : range.endContainer;
    //remove the range
    range?.deleteContents();
    //insert the new text node
    range.insertNode(newNode);
    //normalize the paragraph
    rangeParagraph.normalize();
  };

  return (
    <ReactMarkdown
      rawSourcePos
      sourcePos
      includeElementIndex
      remarkPlugins={[remarkFlexibleMarkers, {}]}
      components={{
        p: ({ children, sourcePosition, node }) => {
          return (
            <p onMouseUp={() => handleMouseUp(sourcePosition?.start.offset, node.children)}>
              {children}
            </p>
          );
        },
        mark: ({ children }) => {
          // get mark children
          const child = Array.isArray(children) ? children[0] : children;
          // get the highlight id
          const id = child?.toString().slice(0, 36);
          // get the highlight text
          const text = child?.toString().slice(36);
          // return the existing highlight component
          return existingHighlightComponent(text, id);
        },
      }}
    >
      {contentWithAnnotations}
    </ReactMarkdown>
  );
};

export default MarkdownWithHighlights;
