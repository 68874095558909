import { Space, Typography } from "antd";
import { useToken } from "components/ui-kit/core/token";
import { RefObject } from "react";
import { UserInterviewActions } from "./user-interview-actions";
import { GeneratedSyntheticUser } from "store/modules/synthetic-users/types";

type UserInterviewUserDetailsProps = {
  generatedSyntheticUserDetails: GeneratedSyntheticUser | null | undefined;
  userInterviewText: string;
  userInterviewId: string;
  index: number | null;
  listRef: RefObject<HTMLDivElement>;
  followUpId: string;
};

export const UserInterviewUserDetails: React.FC<UserInterviewUserDetailsProps> = ({
  generatedSyntheticUserDetails,
  userInterviewText,
  userInterviewId,
  index,
  listRef,
  followUpId,
}) => {
  const token = useToken();

  const data = (generatedSyntheticUserDetails || {}) as GeneratedSyntheticUser;
  const personalData = data.personalInformation || {};

  return (
    <div style={{ width: "100%", justifyContent: "space-between", display: "flex" }}>
      <Space direction="vertical">
        <Typography.Text style={{ marginBottom: token.marginMD }}>
          {personalData["fullName"]}
        </Typography.Text>
        <Typography.Text style={{ marginBottom: token.marginMD, color: "#A9A9A9" }}>
          {personalData["age"]}, {personalData["location"]}
        </Typography.Text>
      </Space>
      <UserInterviewActions
        userInterviewId={userInterviewId}
        userInterviewText={userInterviewText}
        listRef={listRef}
        index={index}
        followUpId={followUpId}
      />
    </div>
  );
};
