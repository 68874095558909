import { Button, Flex, Space, Spin, Typography, theme } from "antd";
import { ErrorFeedback } from "components/shared/error-feedback";
import { isTransportFailure } from "logic/internals/transports/transported-data/is-transport-failure";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ConceptOverview } from "./components/concept-overview";
import {
  generateStudyVisualization,
  killStudyVisualizationGenerating,
  pollStudyVisualization,
} from "store/modules/study-visualization/actions";
import { StudyVisualizationStatus } from "store/modules/study-visualization/schemas";
import { ConceptDetailsTabs } from "./concept-details-tabs";
import { ConceptsMatches } from "store/modules/study-visualization/types";
import useDebouncedCallback from "logic/hooks/use-debounce";

export const VisualSummaryTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const { token } = theme.useToken();

  // Store state
  const studyId = useAppSelector((state) => state.study.data?.id);
  const userInterviews = useAppSelector((state) => state.userInterviews);
  const syntheticUsersData = useAppSelector((state) => state.syntheticUsers.data);
  const studyVisualization = useAppSelector((state) => state.studyVisualization);

  const [selectedConcept, setSelectedConcept] = useState<string | undefined>();

  const allInterviewsAvailable = useMemo(() => {
    const allInterviews = Object.values(userInterviews.data || {});

    return (
      !!allInterviews &&
      !userInterviews.loading &&
      !allInterviews.some((interview) => interview.loading) &&
      !allInterviews.some((interview) => interview.error)
    );
  }, [userInterviews]);

  const computedStudyId = useMemo(() => {
    return studyId || Object.values(userInterviews.data || {})[0]?.data.studyId;
  }, [studyId, userInterviews.data]);

  const studyVisualizationPreload = useMemo(
    () => studyVisualization.data?.studyVisualizationPreload,
    [studyVisualization.data?.studyVisualizationPreload]
  );
  const studyVisualizationData = useMemo(
    () => studyVisualization.data?.studyVisualization,
    [studyVisualization.data?.studyVisualization]
  );

  const hasEnoughInterviewsForVisualization = useMemo(
    () => !!syntheticUsersData?.length && syntheticUsersData?.length >= 6,
    [syntheticUsersData]
  );

  const defaultConcept = useMemo(() => {
    const concepts: ConceptsMatches[] = [];

    studyVisualizationData?.concepts_matched
      .filter((concept) => concept.id !== "All")
      .map((item) => {
        const exists = concepts.find((concept) => concept.concept === item.concept);
        if (!exists) {
          concepts.push(item);
        }
      });

    return concepts[0];
  }, [studyVisualizationData?.concepts_matched]);

  const handleGenerateVisualization = useDebouncedCallback(() => {
    if (!computedStudyId) return;
    dispatch(generateStudyVisualization({ studyId: computedStudyId }));
  });

  useEffect(() => {
    if (computedStudyId && !studyVisualizationPreload) {
      dispatch(pollStudyVisualization({ studyId: computedStudyId }));
    }
  }, [studyVisualizationPreload, computedStudyId, dispatch]);

  useEffect(() => {
    if (!selectedConcept && defaultConcept) {
      setSelectedConcept(defaultConcept.concept);
    }
  }, [defaultConcept, selectedConcept]);

  useEffect(() => {
    return () => {
      // Kill the generating process when the component unmounts
      killStudyVisualizationGenerating();
    };
  }, []);

  return (
    <>
      <style jsx global>
        {`
          /* styles.css */
          .markdown {
            table {
              border-collapse: collapse;
              margin: 16px;
            }

            th,
            td {
              padding: 6px 13px;
              border: 1px solid black;
            }

            p {
              line-height: 1.5;
            }
          }
        `}
      </style>
      <Spin
        spinning={!!studyVisualization.loading && !studyVisualization.generating}
        style={{
          maxWidth: "978px",
          alignContent: "center",
          margin: "auto",
          backgroundColor: "#FAFAFA",
          padding: token.paddingXL,
          marginBottom: 24,
        }}
      >
        {/* Default error state */}
        {isTransportFailure(studyVisualization.error) && (
          <ErrorFeedback error={studyVisualization.error} style={{ marginBottom: token.margin }} />
        )}

        {!studyVisualizationData &&
          !studyVisualizationPreload &&
          !studyVisualization.generating &&
          (hasEnoughInterviewsForVisualization ? (
            <Flex justify="center" align="center" style={{ marginTop: 32 }}>
              <Button
                type="primary"
                onClick={handleGenerateVisualization}
                disabled={!allInterviewsAvailable}
              >
                Generate Concept Analysis
              </Button>
            </Flex>
          ) : (
            <Flex justify="center" align="center" style={{ marginTop: 32 }}>
              <Typography.Title level={4}>
                You need at least 6 interviews to generate a concept analysis.
              </Typography.Title>
            </Flex>
          ))}

        {studyVisualization.generating && (
          <Flex justify="center" align="center" style={{ marginTop: 32 }}>
            <Typography.Title level={4}>
              Generating concept analysis, this may take a couple of minutes...
            </Typography.Title>
          </Flex>
        )}

        {studyVisualizationPreload?.status === StudyVisualizationStatus.FAILED && (
          <Flex justify="center" align="center" style={{ marginTop: 32 }}>
            <Typography.Title level={4} type="danger">
              Failed to generate concept analysis. Please try again.
            </Typography.Title>
          </Flex>
        )}

        {studyVisualizationData && (
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <ConceptOverview setSelectedConcept={setSelectedConcept} />
            <ConceptDetailsTabs
              selectedConcept={selectedConcept}
              setSelectedConcept={setSelectedConcept}
            />
          </Space>
        )}
      </Spin>
    </>
  );
};
