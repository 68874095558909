import { Card, Flex, Typography, Space, Progress } from "antd";
import { useExtraToken } from "components/ui-kit/core/extra-token";

export const SurveysCard: React.FC = () => {
  const { serifFont } = useExtraToken();

  const progressMockData = [
    { name: "concept 1", percentage: 33, fraction: "3/9" },
    { name: "concept 2", percentage: 66, fraction: "6/9" },
    { name: "concept 3", percentage: 100, fraction: "9/9" },
  ];

  return (
    <Card
      style={{
        backgroundColor: "#F6FFED",
        height: "100%",
        position: "relative",
      }}
      styles={{ body: { height: "100%", minHeight: 300 } }}
    >
      {/* Badge */}
      <div
        style={{
          position: "absolute",
          top: 30,
          right: -16,
          padding: "0 8px",
          borderRadius: 20,
          backgroundColor: "#6938EF",
          color: "#FFFFFF",
        }}
      >
        Enterprise only
      </div>
      {/* Content */}

      <Flex vertical justify="space-between" style={{ height: "100%" }}>
        <div>
          <Flex justify="space-between" style={{ marginBottom: 8 }} align="start">
            <Typography.Title
              level={2}
              style={{ fontFamily: serifFont, fontWeight: 400, marginBottom: 0 }}
            >
              Synthetic <br /> Surveys
            </Typography.Title>
          </Flex>
        </div>

        <Flex align="end" gap={16}>
          <Typography.Paragraph style={{ maxWidth: "50%" }}>
            Run up to 1000 surveys at the same time. Quantitative research redefined.
          </Typography.Paragraph>

          {/* Progress Bars */}
          <Space direction="vertical" style={{ width: "50%" }}>
            {progressMockData.map((data, index) => (
              <div key={index}>
                <Flex justify="space-between">
                  <Typography.Paragraph style={{ marginBottom: 0 }}>
                    {data.name}
                  </Typography.Paragraph>
                  <Typography.Paragraph style={{ marginBottom: 0 }}>
                    {data.fraction}
                  </Typography.Paragraph>
                </Flex>
                <Progress
                  trailColor="#FFFFFF"
                  strokeColor="#000000"
                  percent={data.percentage}
                  showInfo={false}
                />
              </div>
            ))}
          </Space>
        </Flex>
      </Flex>
    </Card>
  );
};
