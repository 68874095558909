// React
import { useEffect, useState } from "react";
// Icons
// Components
import { Card, Divider, Spin, Typography } from "antd";
import { AddRequirementInput } from "./add-requirement-input";
import { RequirementInput } from "./requirement-input";
import { GeneratedSyntheticUser } from "store/modules/synthetic-users/types";
import { useExtraToken } from "components/ui-kit/core/extra-token";
// Hooks
// Types

interface SyntheticUserCardProps {
  data: GeneratedSyntheticUser;
  isEditable?: boolean;
  loading?: boolean;
  onAddRequirement: (data: GeneratedSyntheticUser, newRequirement: Record<string, string>) => void;
  onDeleteRequirement: (data: GeneratedSyntheticUser, requirementKey: string) => void;
  onUpdateRequirement: (
    data: GeneratedSyntheticUser,
    requirementKey: string,
    newValue: string
  ) => void;
}

export function SyntheticUserCard({
  data,
  loading,
  isEditable = true,
  onAddRequirement,
  onDeleteRequirement,
  onUpdateRequirement,
}: SyntheticUserCardProps) {
  const { serifFont } = useExtraToken();
  const [newRequirement, setNewRequirement] = useState<Record<string, string>>({
    [""]: "",
  });
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const handleAddNewRequirement = () => {
    onAddRequirement(data, newRequirement);
    setNewRequirement({ [""]: "" });
    setIsUpdating(true);
  };

  const handleUpdateRequirement = (
    data: GeneratedSyntheticUser,
    requirementKey: string,
    newValue: string
  ) => {
    onUpdateRequirement(data, requirementKey, newValue);
    setIsUpdating(true);
  };

  const handleDeleteRequirement = (data: GeneratedSyntheticUser, requirementKey: string) => {
    onDeleteRequirement(data, requirementKey);
    setIsUpdating(true);
  };

  useEffect(() => {
    if (isUpdating && !loading) {
      setIsUpdating(false);
    }
  }, [loading, isUpdating]);

  return (
    <Spin spinning={isUpdating}>
      <Card styles={{ header: { background: "transparent", borderBottom: 0 } }} key={data.id}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "4px 0",
          }}
        >
          <Typography.Title level={4} style={{ fontFamily: serifFont, fontWeight: 400 }}>
            {data.personalInformation && data.personalInformation["fullName"]}
          </Typography.Title>
        </div>
        <div style={{ position: "relative", overflowY: "auto" }}>
          {data.personalInformation && (
            <div>
              {Object.entries(data.personalInformation).map(([key, value]) => (
                <RequirementInput
                  key={key}
                  requirement={key}
                  value={value}
                  data={data}
                  isEditable={false}
                />
              ))}
            </div>
          )}
          <Divider style={{ marginBlock: "6px" }} />
          {data.miscellaneous && (
            <div>
              {Object.entries(data.miscellaneous).map(([key, value]) => (
                <RequirementInput
                  data={data}
                  key={key}
                  requirement={key}
                  value={value}
                  onDelete={handleDeleteRequirement}
                  onUpdate={handleUpdateRequirement}
                  isEditable={isEditable}
                />
              ))}
            </div>
          )}
        </div>
        <footer>
          {isEditable && (
            <AddRequirementInput
              data={data}
              newRequirement={newRequirement}
              setNewRequirement={setNewRequirement}
              onAddRequirement={handleAddNewRequirement}
            />
          )}
        </footer>
      </Card>
    </Spin>
  );
}
