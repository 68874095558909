import { Card, Col, Flex, Modal, Row, Typography } from "antd";
import { GlobalToken, OverrideToken } from "antd/es/theme/interface";
import { useToken } from "components/ui-kit/core/token";
import { Fragment, RefObject, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { UserInterviewFeedback } from "./user-interview-feedback";
import { PersonaCard } from "./persona-card";
import { UserInterviewActions } from "./user-interview-actions";
import { UserInterviewFollowUpContainer } from "./user-interview-follow-up";
import { InterviewAnnotation, UserInterviewState } from "store/modules/user-interviews/types";
import { useAppDispatch } from "store/hooks";
import {
  addUserInterviewAnnotation,
  deleteUserInterviewAnnotation,
  regenerateUserInterview,
  updateUserInterviewAnnotationLabel,
} from "store/modules/user-interviews/actions";
import { InterviewFailed } from "./interview-failed";
import MarkdownWithHighlights from "components/ui-kit/components/markdown-with-highlights";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { UserInterviewAnnotation } from "./user-interview-annotation";

import { useExtraToken } from "components/ui-kit/core/extra-token";
import {
  InsightsPanelTabKeys,
  useStudyContainerContext,
} from "templates/index/workspace-page/contexts/insights-panel";

export const USER_INTERVIEW_FEEDBACK_CLASS_NAME = "user-interview-feedback";
export const USER_INTERVIEW_OPEN_CHAT_BUTTON_CLASS_NAME = "user-interview-open-chat-button";

/**
 * Renders the user interview content based on the user interview state and token styles.
 * - User interview messages come with types "human" (researcher) or "llm" (respondent)
 * - We need to render the messages in a way that the respondent's message is always followed by the researcher's message
 * - Researcher's message is highlighed with typography title
 * @param userInterviewState - The state of the user interview
 * @param token - The token styles for styling the content
 */
function renderInterview(
  userInterview: UserInterviewState,
  token: GlobalToken & OverrideToken,
  addAnnotation: (
    annotation: string,
    startIndex: number,
    endIndex: number,
    userInterviewId: string,
    conversationId: string,
    label: string
  ) => void,
  deleteAnnotation: (annotationId: string, userInterviewId: string) => void,
  updateAnnotation: (annotationId: string, userInterviewId: string, label: string) => void,
  navigateToAnnotationsTab: () => void,
  annotations?: InterviewAnnotation[] | null,
  annotationsEnabled?: boolean,
  serifFont?: string
) {
  return (
    <>
      {userInterview.data.userInterviewWithTypes?.map((interview, index) => {
        const currentInterviewAnswer =
          userInterview.data.userInterviewWithTypes &&
          userInterview.data.userInterviewWithTypes[index + 1];

        const currentInterviewAnnotations =
          annotations &&
          annotations
            .filter(
              (annotation) => annotation.conversationId === currentInterviewAnswer?.conversation_id
            )
            .sort((a, b) => a.startIndex - b.startIndex);

        const getAnnotationById = (id: string) =>
          currentInterviewAnnotations?.find((annotation) => annotation.id === id);

        return interview.message.trim() === "" ? (
          <></>
        ) : (
          <Fragment key={`${interview.message}-${index}`}>
            {(interview.type === "human" || interview.type === "researcher") && (
              <Card
                style={{
                  marginTop: token.margin,
                  borderBlockColor: "transparent",
                  borderLeftColor: "transparent",
                  borderRightColor: "transparent",
                }}
              >
                <Typography.Title level={4} style={{ fontFamily: serifFont }}>
                  {index / 2 + 1} - {interview.message.trim()}
                </Typography.Title>
                <div
                  className="insight-panel-user-interview-text"
                  style={{ marginBottom: token.margin }}
                >
                  {annotationsEnabled ? (
                    <MarkdownWithHighlights
                      newHighlightComponent={(text, startIndex, endIndex, onClose) => {
                        if (!currentInterviewAnswer?.conversation_id) return;
                        return (
                          <UserInterviewAnnotation
                            onSubmit={(label) => {
                              addAnnotation(
                                text,
                                startIndex,
                                endIndex,
                                userInterview.data.userInterviewId,
                                currentInterviewAnswer?.conversation_id,
                                label
                              );
                            }}
                            onClose={onClose}
                            defaultOpen={true}
                          >
                            {text}
                          </UserInterviewAnnotation>
                        );
                      }}
                      existingHighlightComponent={(text, id) => {
                        const annotation = getAnnotationById(id || "");
                        return (
                          <UserInterviewAnnotation
                            id={id}
                            label={annotation?.label || ""}
                            onSubmit={(label) => {
                              if (annotation?.label === label) {
                                deleteAnnotation(id || "", userInterview.data.userInterviewId);
                              } else {
                                updateAnnotation(
                                  id || "",
                                  userInterview.data.userInterviewId,
                                  label
                                );
                              }
                            }}
                            onNavigateToAnnotation={() => {
                              navigateToAnnotationsTab();
                              setTimeout(() => {
                                if (!id) return;
                                // get the annotation element in annotations tab
                                const annotation = document.querySelector(
                                  `[data-annotations-tab-annotation-id="${id}"]`
                                ) as HTMLElement;

                                // scroll to the annotation
                                annotation?.scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                });

                                // highlight the annotation
                                annotation?.style.setProperty("border", "2px solid #000000");

                                // remove the highlight after 2 seconds
                                setTimeout(() => {
                                  annotation?.style.removeProperty("border");
                                }, 2000);
                              }, 500);
                            }}
                          >
                            {text}
                          </UserInterviewAnnotation>
                        );
                      }}
                      highlightedText={currentInterviewAnnotations?.map((annotation) => ({
                        id: annotation.id,
                        content: annotation.annotation,
                        startIndex: annotation.startIndex,
                        endIndex: annotation.endIndex,
                      }))}
                    >
                      {currentInterviewAnswer?.message.trim() || ""}
                    </MarkdownWithHighlights>
                  ) : (
                    <ReactMarkdown>{currentInterviewAnswer?.message.trim() || ""}</ReactMarkdown>
                  )}
                </div>
              </Card>
            )}
          </Fragment>
        );
      })}
    </>
  );
}

/**
 * Renders the legacy interview content based on the formatted questions and token styles.
 * This is legacy and, if everything goes well, will be removed in the future.
 * - This rendering generates a "block" for each message (chat style)
 * @param formattedQuestions - The array of formatted questions for the interview
 * @param token - The token styles for styling the content
 */
function renderLegacyInterview(
  formattedQuestions: Array<string> | undefined,
  token: GlobalToken & OverrideToken
) {
  return (
    <>
      {formattedQuestions?.map(
        (interview, i) =>
          !!interview?.trim().length && (
            <Card
              key={`${interview}-${i}`}
              style={{
                marginTop: token.margin,
                borderBlockColor: "transparent",
                borderLeftColor: "transparent",
                borderRightColor: "transparent",
              }}
            >
              <div
                className="insight-panel-user-interview-text"
                style={{ marginBottom: token.margin }}
              >
                <ReactMarkdown className="insight-panel-user-interview-markdown">
                  {interview.trim()}
                </ReactMarkdown>
              </div>
            </Card>
          )
      )}
    </>
  );
}

type UserInterviewContentProps = {
  userInterview: UserInterviewState;
  listRef: RefObject<HTMLDivElement>;
  followUpId: string;
};

export const useUserInterviewContentId = "user-interview-content";

export const UserInterviewContent: React.FC<UserInterviewContentProps> = ({
  userInterview,
  listRef,
  followUpId,
}) => {
  const token = useToken();
  const { serifFont } = useExtraToken();
  const dispatch = useAppDispatch();

  const formattedQuestions = useMemo(
    () =>
      Array.isArray(userInterview.data.userInterview)
        ? userInterview.data.userInterview.map((interview) => interview?.trim())
        : Array(userInterview.data.userInterview?.trim() || ""),
    [userInterview.data.userInterview]
  );

  // FEATURE FLAG
  const annotationsEnabled = useFeatureFlagEnabled("user-interview-annotations");

  const { setInsightsPanelTab } = useStudyContainerContext();

  const [interviewToReplace, setInterviewToReplace] = useState<undefined | string>();

  const isNewInterview =
    !!userInterview.data.userInterviewWithTypes &&
    !!userInterview.data.userInterviewWithTypes.length;

  const handleAddAnnotation = (
    annotation: string,
    startIndex: number,
    endIndex: number,
    userInterviewId: string,
    conversationId: string,
    label: string
  ) => {
    dispatch(
      addUserInterviewAnnotation({
        conversationId,
        userInterviewId,
        annotation,
        startIndex,
        endIndex,
        label,
      })
    );
  };

  const handleDeleteAnnotation = (annotationId: string, userInterviewId: string) => {
    dispatch(
      deleteUserInterviewAnnotation({
        annotationId,
        userInterviewId,
      })
    );
  };

  const handleUpdateAnnotation = (annotationId: string, userInterviewId: string, label: string) => {
    dispatch(
      updateUserInterviewAnnotationLabel({
        annotationId,
        userInterviewId,
        label,
      })
    );
  };

  const handleNavigateToAnnotationsTab = () => {
    setInsightsPanelTab(InsightsPanelTabKeys.ANNOTATIONS);
  };

  return (
    <>
      <Modal
        open={!!interviewToReplace}
        closable={false}
        title={<>{"We'll replace this interview for you!"}</>}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={() => {
          // TODO: regenerate the interview
          setInterviewToReplace(undefined);
        }}
      >
        {
          "Thank you for letting us know this interview wasn't quite there. We'll replace it with a new one."
        }
      </Modal>

      <div
        style={{ maxWidth: "978px", alignContent: "center", margin: "auto" }}
        id={useUserInterviewContentId}
      >
        {userInterview.data && (
          <Row gutter={16}>
            <Col span={9}>
              <PersonaCard
                generatedSyntheticUserDetail={
                  userInterview.data.generatedSyntheticUserPersonalityDetails
                }
              />
            </Col>
            <Col
              span={15}
              style={{
                backgroundColor: "#FAFAFA",
                padding: token.padding,
              }}
            >
              {/* TODO: revert second part of the condition once BE fixes user interviews errors */}
              {userInterview.error ||
              (!userInterview.loading && !userInterview.data.userInterview.length) ? (
                <InterviewFailed
                  onRetry={() =>
                    dispatch(
                      regenerateUserInterview({
                        userInterviewId: userInterview.data.userInterviewId,
                      })
                    )
                  }
                />
              ) : (
                <>
                  <Flex justify="end">
                    <UserInterviewActions
                      index={null}
                      userInterviewText={
                        Array.isArray(userInterview.data.userInterview)
                          ? userInterview.data.userInterview.join(" ")
                          : userInterview.data.userInterview
                      }
                      userInterviewId={userInterview.data.userInterviewId}
                      listRef={listRef}
                      followUpId={followUpId}
                    />
                  </Flex>
                  <>
                    {
                      // Old interviews don't have types, so we need to check if the interview has types or not
                      // If it has types, we render the interview with types, otherwise we render the legacy interview
                      isNewInterview
                        ? renderInterview(
                            userInterview,
                            token,
                            handleAddAnnotation,
                            handleDeleteAnnotation,
                            handleUpdateAnnotation,
                            handleNavigateToAnnotationsTab,
                            userInterview.data.annotations,
                            annotationsEnabled,
                            serifFont
                          )
                        : renderLegacyInterview(formattedQuestions, token)
                    }
                    <UserInterviewFollowUpContainer
                      userInterviewId={userInterview.data.userInterviewId}
                    />
                    <div
                      style={{
                        marginTop: token.margin,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className={USER_INTERVIEW_FEEDBACK_CLASS_NAME}>
                        <UserInterviewFeedback
                          userInterview={userInterview}
                          onBadFeedback={() => {
                            setInterviewToReplace(userInterview.data.userInterviewId);
                          }}
                        />
                      </div>
                    </div>
                  </>
                </>
              )}
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
