import { Card, Typography } from "antd";
import { RefObject, useMemo } from "react";
import { convertTagsToListItems } from "../utils/convert-tags-to-list-items";
import { stripBrTags } from "../utils/strip-br-tags";
import { useAppSelector } from "store/hooks";

type ConceptDetailsCardProps = {
  selectedConcept: string;
  conceptDetailsRef: RefObject<HTMLDivElement>;
};

export const ConceptDetailsCard: React.FC<ConceptDetailsCardProps> = ({
  selectedConcept,
  conceptDetailsRef,
}) => {
  const studyVisualizationData = useAppSelector(
    (state) => state.studyVisualization.data?.studyVisualization
  );

  const conceptDetails = useMemo(() => {
    const details = Object.entries(studyVisualizationData?.concept_detail.concepts || {}).find(
      ([key]) => key === selectedConcept
    )?.[1];

    return {
      summary: details?.summary ?? undefined,
      criteria: details?.criteria ?? undefined,
      matches: details?.concept_matches ?? undefined,
      examples: details?.representative_examples ?? undefined,
    };
  }, [selectedConcept, studyVisualizationData?.concept_detail.concepts]);

  const { criteria, summary, matches, examples } = conceptDetails;

  const examplesListItems = examples ? convertTagsToListItems(examples) : undefined;

  return (
    <Card ref={conceptDetailsRef}>
      {criteria && (
        <>
          <Typography.Paragraph strong>Criteria:</Typography.Paragraph>
          <Typography.Paragraph>{stripBrTags(criteria)}</Typography.Paragraph>
        </>
      )}

      {summary && (
        <>
          <Typography.Paragraph strong>Summary:</Typography.Paragraph>
          <Typography.Paragraph>{stripBrTags(summary)}</Typography.Paragraph>
        </>
      )}

      {matches && (
        <>
          <Typography.Paragraph strong>
            Concept Matches:{" "}
            <Typography.Text style={{ fontWeight: 400 }}>{stripBrTags(matches)}</Typography.Text>
          </Typography.Paragraph>
        </>
      )}

      {examplesListItems && (
        <>
          <Typography.Paragraph strong>Representative Examples:</Typography.Paragraph>
          <ul>
            {examplesListItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </>
      )}
    </Card>
  );
};
