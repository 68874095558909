import { Col, Row, Typography } from "antd";
import { InterviewModeCard, InterviewModeCardProps } from "./interview-mode-card";
import problemExplorationSvg from "assets/problem_exploration.svg";
import customScriptSvg from "assets/custom-script.svg";
import researchGoalSvg from "assets/research_goal.svg";
import conceptTestingSvg from "assets/concept-testing.svg";
import ethnographicResearchSvg from "assets/ethnographic-research.svg";
// import messageTestingSvg from "assets/message_testing.svg";
import { ProjectSettings } from "store/modules/projects/types";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setProjectSettings } from "store/modules/projects/actions";
import { HeaderCard, HeaderCardProps } from "./header-card";
import { RagCard } from "./rag-card";
import { SurveysCard } from "./surveys-card";
import { TutorialsCard } from "./tutorials-cards";
import { useSidebarMenuContext } from "components/routing/page/layouts/with-sidebar-menu/context";
import Link from "next/link";
import { SignupRequiredTrigger } from "templates/index/workspace-page/signup-required-alert/signup-required-trigger";
import { PlanUpgradeModal } from "../../plan-upgrade-modal/plan-upgrade-modal";
import { useState } from "react";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { useOngoingWalkthrough } from "logic/hooks/ongoing-walkthrough";
import { useFeatureFlagEnabled } from "posthog-js/react";

type InterviewModeCard = InterviewModeCardProps & {
  span: number;
  mode: ProjectSettings;
};

export const NewUIHome: React.FC = () => {
  const dispatch = useAppDispatch();

  const { ragEnabled } = useSidebarMenuContext();
  const activeSubscription = useAppSelector(
    (state) => state.workspaces.data.workspace?.activeSubscription
  );

  const { ongoingWalkthrough } = useOngoingWalkthrough();

  const ethnographicResearchFeatureEnabled = useFeatureFlagEnabled("ethnographic-research");

  const [showPlanUpgradeModal, setShowPlanUpgradeModal] = useState(false);

  const headerCards = [
    {
      text: "Watch the 30 seconds intro",
      url: "https://www.youtube.com/watch?v=tIeysGYnfPs",
      cardType: "youtube",
      span: 8,
    },
    {
      text: "See how a Synthetic User compares to organic users",
      url: "https://www.syntheticusers.com/science-posts/how-we-measure-success",
      cardType: "website",
      span: 16,
    },
  ];

  const interviewModeCards: InterviewModeCard[] = [
    {
      mode: ProjectSettings.DynamicInterviewMode,
      title: "Research Goal",
      interviewNumber: 1,
      description:
        "Set your research goal and let our multi-agent architecture drive your interviews.",
      imageSrc: (researchGoalSvg as { src: string }).src,
      imageOffset: { x: -30, y: 45 },
      span: 12,
      onClick: () => {
        if (!activeSubscription && !ongoingWalkthrough) return setShowPlanUpgradeModal(true);

        dispatch(setProjectSettings(ProjectSettings.DynamicInterviewMode));
      },
    },
    {
      mode: ProjectSettings.CustomScriptMode,
      title: "Custom Script",
      interviewNumber: 2,
      description: "Use your own questions (up to 10).",
      imageSrc: (customScriptSvg as { src: string }).src,
      imageOffset: { x: 30, y: 25 },
      span: 12,
      onClick: () => {
        if (!activeSubscription && !ongoingWalkthrough) return setShowPlanUpgradeModal(true);

        dispatch(setProjectSettings(ProjectSettings.CustomScriptMode));
      },
    },
    {
      mode: ProjectSettings.ProblemExplorationMode,
      title: "Problem Exploration",
      interviewNumber: 3,
      description:
        "You wish to explore the pains and needs of an audience as you look for opportunities.",
      imageSrc: (problemExplorationSvg as { src: string }).src,
      imageOffset: { x: 30, y: 25 },
      span: 12,
      onClick: () => {
        if (!activeSubscription && !ongoingWalkthrough) return setShowPlanUpgradeModal(true);

        dispatch(setProjectSettings(ProjectSettings.ProblemExplorationMode));
      },
    },
    {
      mode: ProjectSettings.SolutionFeedbackMode,
      title: "Concept Testing",
      interviewNumber: 4,
      description:
        "You want to validate a concept with a particular audience. This is the best interview type for it.",
      imageSrc: (conceptTestingSvg as { src: string }).src,
      span: 12,
      onClick: () => {
        if (!activeSubscription && !ongoingWalkthrough) return setShowPlanUpgradeModal(true);

        dispatch(setProjectSettings(ProjectSettings.SolutionFeedbackMode));
      },
    },
  ];

  if (ethnographicResearchFeatureEnabled) {
    interviewModeCards.push({
      mode: ProjectSettings.EthnographicResearchMode,
      title: "Ethnographic Research",
      interviewNumber: 5,
      description:
        "Systematically studying people from the subject's perspective to understand their everyday lives and practices.",
      imageSrc: (ethnographicResearchSvg as { src: string }).src,
      span: 12,
      onClick: () => {
        if (!activeSubscription && !ongoingWalkthrough) return setShowPlanUpgradeModal(true);

        dispatch(setProjectSettings(ProjectSettings.EthnographicResearchMode));
      },
    });
  }

  return (
    <>
      <PlanUpgradeModal
        error={TransportFailure.SubscriptionNotFound}
        visible={showPlanUpgradeModal}
        setVisible={setShowPlanUpgradeModal}
      />
      <Row style={{ padding: 32 }} gutter={[16, 16]}>
        {/* Videos */}
        {headerCards.map((card, i) => (
          <Col key={`video-card-${i}`} span={card.span}>
            <Link
              href={card.url}
              rel="noopener noreferrer"
              target="_blank"
              // style={{ textDecoration: "none" }}
            >
              <HeaderCard
                text={card.text}
                cardType={card.cardType as HeaderCardProps["cardType"]}
              />
            </Link>
          </Col>
        ))}

        {/* Qualitative Research */}
        <Col span={24} style={{ marginBlock: 16 }}>
          <Typography.Title level={5} style={{ marginBottom: 0, marginLeft: 24, fontWeight: 700 }}>
            Qualitative Research
          </Typography.Title>
        </Col>
        {interviewModeCards.map((card, i) => (
          <Col key={`interview-mode-selector-card-${i}`} span={card.span}>
            <SignupRequiredTrigger>
              <InterviewModeCard
                title={card.title}
                interviewNumber={card.interviewNumber}
                description={card.description}
                imageSrc={card.imageSrc}
                onClick={card.onClick}
                imageOffset={card.imageOffset}
                imageSize={card.imageSize}
              />
            </SignupRequiredTrigger>
          </Col>
        ))}
        <Col span={12}>{ragEnabled && <RagCard />}</Col>

        {/* Quantitative Research */}
        <Col span={24} style={{ marginBlock: 16 }}>
          <Typography.Title level={5} style={{ marginBottom: 0, marginLeft: 24, fontWeight: 700 }}>
            Quantitative Research
          </Typography.Title>
        </Col>
        <Col span={12}>
          <SurveysCard />
        </Col>

        {/* Tutorials */}
        <Col span={24} style={{ marginBlock: 16 }}>
          <Typography.Title level={5} style={{ marginBottom: 0, marginLeft: 24, fontWeight: 700 }}>
            Tutorials
          </Typography.Title>
        </Col>
        <Col span={24}>
          <TutorialsCard />
        </Col>
      </Row>
    </>
  );
};
