import { Card, Col, Row, Typography } from "antd";
import { useState } from "react";

type SuggestionCardProps = {
  suggestions: string[];
  onClick: (suggestion: string) => void;
};

export const Suggestions: React.FC<SuggestionCardProps> = ({ suggestions, onClick }) => {
  const [suggestionSelected, setSuggestionSelected] = useState<boolean>(false);

  const handleClick = (suggestion: string) => {
    setSuggestionSelected(true);
    onClick(suggestion);
  };

  return (
    <Row gutter={[16, 16]} wrap>
      {suggestions.map((suggestion, i) => (
        <Col span={8} key={`suggestion-card-${i}`}>
          <Card
            size="small"
            style={{
              height: "100%",
              cursor: suggestionSelected ? "default" : "pointer",
              backgroundColor: "transparent",
              color: suggestionSelected ? "rgba(0, 0, 0, 0.45)" : "initial",
            }}
            styles={{ body: { height: "100%" } }}
            onClick={() => !suggestionSelected && handleClick(suggestion)}
          >
            <Typography.Paragraph
              style={{ marginBlock: 0 }}
              ellipsis={{ rows: 2, tooltip: suggestion }}
            >
              {suggestion}
            </Typography.Paragraph>
          </Card>
        </Col>
      ))}
    </Row>
  );
};
