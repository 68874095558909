/* eslint-disable @next/next/no-img-element */
import { Card, Flex, Space, Typography } from "antd";
import { RefObject } from "react";
import { useAppSelector } from "store/hooks";
import { getStudyAnnotations } from "store/selectors/study-annotations";

import annotationsSvg from "assets/annotations.svg";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import {
  INTERVIEW_TAB_KEY,
  useStudyContainerContext,
} from "templates/index/workspace-page/contexts/insights-panel";

export enum AnnotationType {
  BLUE = "blue",
  GREEN = "green",
  RED = "red",
}

export const annotationColors = {
  [AnnotationType.BLUE]: "#82D2FF",
  [AnnotationType.GREEN]: "#8BDA84",
  [AnnotationType.RED]: "#FF9090",
};

export const isAnnotationType = (label?: string): label is AnnotationType => {
  return Object.values(AnnotationType).includes(label as AnnotationType);
};

type AnnotationsTabProps = {
  listRef: RefObject<HTMLElement>;
};

export const AnnotationsTab: React.FC<AnnotationsTabProps> = ({ listRef }) => {
  const studyAnnotations = useAppSelector(getStudyAnnotations);
  const selectedAudiencesIds = useAppSelector((state) => state.audiences.data.selectedAudiencesIds);
  const userInterviews = useAppSelector((state) => state.userInterviews);

  const { serifFont } = useExtraToken();

  const { setInsightsPanelTab, setCurrentPage } = useStudyContainerContext();

  const handleClick = (annotationId: string, userInterviewId: string) => {
    const userInterview = userInterviews?.data && userInterviews?.data[userInterviewId];

    const audienceId = userInterview?.data?.syntheticUserId;

    const tabIndex = selectedAudiencesIds?.findIndex((id) => id === audienceId);

    const userInterviewPage = Object.values(userInterviews.data || {}).findIndex(
      (userInterview) => userInterview.data.userInterviewId === userInterviewId
    );

    setCurrentPage(userInterviewPage);

    setInsightsPanelTab(`${INTERVIEW_TAB_KEY}${tabIndex || 0}`);

    setTimeout(() => {
      const annotation = listRef.current?.querySelector(`[data-annotation-id="${annotationId}"]`);

      if (annotation) {
        annotation.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        setTimeout(() => {
          annotation.dispatchEvent(new MouseEvent("click", { bubbles: true }));
        }, 500);
      }
    }, 500);
  };

  const getAnnotationColor = (label?: string) => {
    if (isAnnotationType(label)) {
      return annotationColors[label];
    }

    return annotationColors[AnnotationType.BLUE];
  };

  return (
    <Flex vertical>
      {studyAnnotations.length ? (
        studyAnnotations.map((interview) => (
          <Space
            direction="vertical"
            key={interview.syntheticUserName}
            style={{ marginBottom: 20 }}
          >
            <Typography.Title
              level={4}
              style={{ marginBlock: 8, fontFamily: serifFont, fontWeight: 400 }}
            >
              {interview.syntheticUserName}
            </Typography.Title>
            <Space direction="vertical" style={{ width: "100%" }}>
              {Object.values(interview.annotations).map((questionAnnotations, index) => (
                <Card key={`${interview.syntheticUserName}-${index}`} style={{ width: "100%" }}>
                  <Space direction="vertical">
                    <Typography.Text style={{ fontSize: 18 }} strong>
                      {questionAnnotations.question}
                    </Typography.Text>
                    {questionAnnotations.annotations.map((annotation) => (
                      <mark
                        key={annotation.id}
                        data-annotations-tab-annotation-id={annotation.id}
                        style={{
                          cursor: "pointer",
                          backgroundColor: getAnnotationColor(annotation?.label || ""),
                        }}
                        onClick={() => handleClick(annotation.id, annotation.userInterviewId)}
                      >
                        {annotation.annotation}
                      </mark>
                    ))}
                  </Space>
                </Card>
              ))}
            </Space>
          </Space>
        ))
      ) : (
        <Flex align="center" vertical style={{ marginTop: "40px" }}>
          <Typography.Text
            style={{ fontSize: 22, fontWeight: 400, textAlign: "center", marginBottom: 16 }}
          >
            Navigate to your interviews <br /> and start annotating
          </Typography.Text>
          <img height="120px" src={(annotationsSvg as { src: string }).src} alt="Google" />
        </Flex>
      )}
    </Flex>
  );
};
