import { Tabs, TabsProps, theme } from "antd";
import { getAnalytics } from "logic/analytics/analytics";
import { useEffect, useMemo, useRef } from "react";
import { useAppSelector } from "store/hooks";
import { INSIGHTS_PANEL_SCROLLABLE_CONTAINER_ID } from "../workspace-page-constants";
import { SnapshotCard } from "./tabs/shared/snapshot-card";
import { SummaryTab } from "./tabs/summary-tab";
import { UserInterviewsTab } from "./tabs/user-interview-tab";
import { AnnotationsTab } from "./tabs/annotations-tab";
import { useFeatureFlagEnabled } from "posthog-js/react";
import {
  InsightsPanelTab,
  InsightsPanelTabKeys,
  useStudyContainerContext,
} from "../../contexts/insights-panel";
import { VisualSummaryTab } from "./tabs/visual-summary-tab";
import { useDrawerContext } from "../../contexts/drawer";

const INTERVIEW_TAB_KEY = "interviews-";

function isInsightsPanelTab(value: string): value is InsightsPanelTab {
  const interviewTabKeyPattern = new RegExp(`^${INTERVIEW_TAB_KEY}\\d+$`);
  return (
    interviewTabKeyPattern.test(value) ||
    Object.values(InsightsPanelTabKeys).includes(value as InsightsPanelTabKeys)
  );
}

export const InsightsPanel: React.FC = () => {
  const { token } = theme.useToken();
  const containerRef = useRef<HTMLDivElement>(null);
  const analytics = getAnalytics();

  // FEATURE FLAG
  const annotationsEnabled = useFeatureFlagEnabled("user-interview-annotations");
  const lloomConceptAnalysisEnabled = useFeatureFlagEnabled("lloom-concept-analysis");

  const selectedAudiences = useAppSelector((state) => state.audiences.data.selectedAudiencesIds);
  const { setCurrentAudience, insightsPanelTab, setInsightsPanelTab } = useStudyContainerContext();
  const { drawerOpen } = useDrawerContext();

  useEffect(() => {
    document.getElementById(INSIGHTS_PANEL_SCROLLABLE_CONTAINER_ID)?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const onChange = (key: string) => {
    analytics.track("workspace:study:interviews:switch-tab", { tab: key });

    if (!isInsightsPanelTab(key)) return;

    if (key.startsWith(INTERVIEW_TAB_KEY)) {
      const index = parseInt(key.replace(INTERVIEW_TAB_KEY, ""));
      setCurrentAudience(index);
    }

    setInsightsPanelTab(key);
  };

  const items: TabsProps["items"] = useMemo(() => {
    const tabs: TabsProps["items"] = [];

    const interviewsTabs = selectedAudiences?.map((_, index) => ({
      key: `${INTERVIEW_TAB_KEY}${index}`,
      label: selectedAudiences.length > 1 ? `Interviews ${index + 1}` : "Interviews",
      children: <UserInterviewsTab listRef={containerRef} />,
    }));

    const summaryTab = {
      key: InsightsPanelTabKeys.SUMMARY,
      label: "Report",
      children: <SummaryTab />,
    };

    const visualSummaryTab = {
      key: InsightsPanelTabKeys.VISUAL_SUMMARY,
      label: "Concept Analysis (Beta)",
      children: <VisualSummaryTab />,
    };

    const annotationsTab = {
      key: InsightsPanelTabKeys.ANNOTATIONS,
      label: "Annotations",
      children: <AnnotationsTab listRef={containerRef} />,
    };

    // Composition of tabs
    if (interviewsTabs) tabs.push(...interviewsTabs);
    tabs.push(summaryTab);
    if (lloomConceptAnalysisEnabled) tabs.push(visualSummaryTab);
    if (annotationsEnabled) tabs.push(annotationsTab);

    return tabs;
  }, [selectedAudiences, containerRef, annotationsEnabled, lloomConceptAnalysisEnabled]);

  return (
    <div
      style={{
        minHeight: "100%",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: token.padding,
        paddingBottom: token.padding,
        height: "100%",
      }}
    >
      <div>
        <SnapshotCard style={{ width: "100%", marginBottom: token.margin, marginTop: 0 }} />
        <div ref={containerRef} style={{ scrollMarginTop: drawerOpen ? 0 : 80 }}>
          <Tabs
            style={{ maxWidth: "978px", alignContent: "center", margin: "auto" }}
            items={items}
            activeKey={insightsPanelTab}
            onChange={onChange}
            destroyInactiveTabPane
            renderTabBar={(props, DefaultTabBar) => (
              <div
                style={{
                  position: "sticky",
                  top: drawerOpen ? 0 : 80,
                  zIndex: 11,
                  backgroundColor: "#F6F6FF",
                }}
              >
                <DefaultTabBar {...props} />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};
