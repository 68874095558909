import { App as AntApp } from "antd";
import { useEffect, useState } from "react";

const MESSAGE_KEY = "generating-user-interviews-message";

function GeneratingInterviewsMessageLabel() {
  const [step, setStep] = useState(0);
  const steps = [
    "Identifying potential interviewee and checking availability.",
    "Preparing interview questions.",
    "Scheduling the interview with necessary details.",
    "Conducting the interview, taking detailed notes.",
    "Thanking the interviewee.",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setStep((step) => (step + 1) % steps.length);
    }, 4000);
    return () => clearInterval(interval);
  }, [steps.length]);

  return <>{steps[step]}</>;
}

export function GeneratingUserInterviewsMessage() {
  const { message } = AntApp.useApp();

  useEffect(() => {
    message.loading({
      content: <GeneratingInterviewsMessageLabel />,
      duration: 0,
      key: MESSAGE_KEY,
    });

    return () => {
      message.destroy(MESSAGE_KEY);
    };
  }, [message]);

  return <></>;
}
