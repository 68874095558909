import { Card, Space, Typography } from "antd";
import { useToken } from "components/ui-kit/core/token";
import { RefObject } from "react";
import ReactMarkdown from "react-markdown";
import { UserInterviewUserDetails } from "./user-interview-user-details";
import { UserInterviewState } from "store/modules/user-interviews/types";

type UserInterviewByTopicContentProps = {
  userInterviews: UserInterviewState[];
  listRef: RefObject<HTMLDivElement>;
  currentTopicText: string;
};

export const UserInterviewByTopicContent: React.FC<UserInterviewByTopicContentProps> = ({
  userInterviews,
  listRef,
  currentTopicText,
}) => {
  const token = useToken();

  return (
    <div style={{ maxWidth: "725px", alignContent: "center", margin: "auto" }}>
      <div
        style={{
          backgroundColor: "#FAFAFA",
          borderRadius: token.borderRadius,
          minHeight: "125px",
          padding: token.padding,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Space direction="vertical">
          <Typography.Title level={4}>{currentTopicText}</Typography.Title>
          {userInterviews.map((userInterview, index) => {
            return (
              <Card
                key={userInterview.data.userInterviewId}
                style={{
                  marginTop: token.margin,
                  borderBlockColor: "transparent",
                  borderLeftColor: "transparent",
                  borderRightColor: "transparent",
                }}
              >
                <UserInterviewUserDetails
                  listRef={listRef}
                  index={index}
                  followUpId={userInterview.data.userInterviewId}
                  generatedSyntheticUserDetails={
                    userInterview.data.generatedSyntheticUserPersonalityDetails
                  }
                  userInterviewText={userInterviews.join("\n\n")}
                  userInterviewId={userInterview.data.userInterviewId}
                />
                <div
                  className="insight-panel-user-interview-text"
                  style={{ marginBottom: token.margin, marginTop: token.margin }}
                >
                  <ReactMarkdown className="insight-panel-user-interview-markdown">
                    {userInterview.data.userInterviewWithTopics?.find(
                      (topic) => topic.topic === currentTopicText
                    )?.message || ""}
                  </ReactMarkdown>
                </div>
              </Card>
            );
          })}
        </Space>
      </div>
    </div>
  );
};
