import { faShare } from "@fortawesome/free-solid-svg-icons";
import { Button, message, Tooltip } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { getAnalytics } from "logic/analytics/analytics";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { STUDY_ROUTE } from "templates/studies/study.routes";
import { useStudyContainerContext } from "../../contexts/insights-panel";

export const ShareButton: React.FC = () => {
  // Initialize the analytics hook
  const analytics = getAnalytics();
  const summary = useAppSelector((state) => state.summary);

  const { currentSummaryIndex } = useStudyContainerContext();

  const currentSummary = useMemo(
    () => summary.data && summary.data[currentSummaryIndex],
    [summary, currentSummaryIndex]
  );

  const disabled = useMemo(
    () => !currentSummary?.summary.length || summary.loading,
    [currentSummary, summary.loading]
  );

  // Handle the button click event
  const handleClick = () => {
    // Throw an error if there is no summary data
    if (!currentSummary?.studyId) return;

    // Get the summary ID
    const { studyId } = currentSummary;

    // Copy the summary URL to the clipboard
    navigator.clipboard.writeText(STUDY_ROUTE.getHrefWithOrigin(studyId));

    // Track the event in analytics
    analytics.track("workspace:study:share", { study_id: studyId });

    // Show the toast
    message.success("Great! The link is now in your clipboard. Just paste it anywhere you want!");
  };

  return (
    <Tooltip
      placement="bottomLeft"
      title={
        disabled
          ? "You need to generate user interviews and a summary before you can share your study"
          : ""
      }
    >
      <Button
        disabled={disabled}
        onClick={handleClick}
        title="Share Summary"
        className="btn btn-link link-body"
        icon={<AntIcon icon={faShare} />} // Added this line
      >
        Share Study
      </Button>
    </Tooltip>
  );
};
