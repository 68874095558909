import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { Button, Typography } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { useToken } from "components/ui-kit/core/token";
import { getAnalytics } from "logic/analytics/analytics";
import { useAppDispatch } from "store/hooks";
import { giveUserInterviewFeedback } from "store/modules/user-interviews/actions";
import { UserInterviewState } from "store/modules/user-interviews/types";

import { SignupRequiredTrigger } from "templates/index/workspace-page/signup-required-alert/signup-required-trigger";

type UserInterviewFeedbackProps = {
  userInterview: UserInterviewState;
  onBadFeedback: () => void | null;
};

export const UserInterviewFeedback: React.FC<UserInterviewFeedbackProps> = ({
  userInterview,
  onBadFeedback,
}) => {
  const dispatch = useAppDispatch();
  const token = useToken();
  const analytics = getAnalytics();

  const handleFeedback = (helpful: boolean) => {
    dispatch(
      giveUserInterviewFeedback({ interviewId: userInterview.data.userInterviewId, helpful })
    );

    !helpful && onBadFeedback();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <style jsx global>
        {`
          .feedback-thumbs-up,
          .feedback-thumbs-down {
            color: ${token.colorTextTertiary};
          }

          .feedback-thumbs-up:hover,
          .feedback-thumbs-up-active {
            color: ${token.colorSuccessText};
          }

          .feedback-thumbs-down:hover,
          .feedback-thumbs-down-active {
            color: ${token.colorErrorText};
          }
        `}
      </style>
      <Typography.Text type="secondary" style={{ margin: 0 }}>
        Was this insightful?
      </Typography.Text>
      <SignupRequiredTrigger>
        <Button
          onClick={() => {
            analytics.track("workspace:study:interviews:feedback", {
              feedback: "positive",
              user_interview_id: userInterview.data.userInterviewId,
            });
            handleFeedback(true);
          }}
          className={`feedback-thumbs-up ${
            userInterview.data.helpful === true ? "feedback-thumbs-up-active" : ""
          }`}
          style={{ marginLeft: token.marginSM }}
          size="small"
          icon={<AntIcon icon={faThumbsUp} />}
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            analytics.track("workspace:study:interviews:feedback", {
              feedback: "negative",
              user_interview_id: userInterview.data.userInterviewId,
            });
            handleFeedback(false);
          }}
          className={`feedback-thumbs-down ${
            userInterview.data.helpful === false ? "feedback-thumbs-down-active" : ""
          }`}
          style={{ marginLeft: token.marginSM }}
          size="small"
          icon={<AntIcon icon={faThumbsDown} />}
        >
          No
        </Button>
      </SignupRequiredTrigger>
    </div>
  );
};
