import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { BaseButtonProps } from "antd/es/button/button";
import { AntIcon } from "components/ui-kit/components/ant-icon";

export type BaseChipProps = {
  onClick: () => void;
  text: string;
  skin: BaseButtonProps["type"];
};

export const BaseChip: React.FC<BaseChipProps> = ({ onClick, text, skin }) => {
  return (
    <Button
      onClick={onClick}
      icon={<AntIcon component={() => <FontAwesomeIcon icon={faArrowRight} />} />}
      type={skin}
      size="middle"
    >
      {text}
    </Button>
  );
};
