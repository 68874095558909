import { Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

type ResearchPlanInputProps = {
  id?: string;
  defaultValue: string;
  onSubmit?: (value: string, id?: string) => void;
  onInputChange?: (value: string) => void;
  onInputFocus?: () => void;
  isEditable?: boolean;
  loading?: boolean;
  compact?: boolean;
};

export const ResearchPlanInput: React.FC<ResearchPlanInputProps> = ({
  id,
  defaultValue,
  isEditable,
  onSubmit,
  onInputChange,
  onInputFocus,
  loading,
  compact,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const form = useForm<{ text: string }>({
    resolver: zodResolver(z.string().min(1)),
    defaultValues: { text: defaultValue },
    reValidateMode: "onChange",
  });

  useEffect(() => {
    form.setValue("text", defaultValue);
  }, [defaultValue, form]);

  useEffect(() => {
    if (submitting && !loading) {
      setSubmitting(false);
    }
  }, [loading, submitting]);

  return (
    <Spin spinning={submitting}>
      <div style={{ width: "100%" }}>
        <form
          onSubmit={form.handleSubmit((formValue) => {
            onSubmit && onSubmit(formValue.text, id);
            setSubmitting(true);
          })}
        >
          <Controller
            control={form.control}
            name="text"
            render={({ field: { value, onChange } }) => (
              <Input.TextArea
                disabled={!isEditable}
                autoSize={{ minRows: compact ? 2 : 5, maxRows: 6 }}
                style={{ resize: "none", backgroundColor: "#FFFFFF" }}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  onInputChange && onInputChange(e.target.value);
                }}
                onFocus={onInputFocus}
                onBlur={(e) => {
                  e.preventDefault();
                  value !== defaultValue && onSubmit && onSubmit(value, id);
                  setSubmitting(true);
                }}
              />
            )}
          />
        </form>
      </div>
    </Spin>
  );
};
