import { Image, Input, Space, Typography } from "antd";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import researchAssistantSvg from "assets/research_assistant.svg";
import { LoadingAnimation } from "./loading-animation";

type AltSelectionInputProps = {
  question: string;
  onSubmit: (value: string) => void;
  loading?: boolean;
};

export const SelectionInput: React.FC<AltSelectionInputProps> = ({
  question,
  onSubmit,
  loading,
}) => {
  const form = useForm<{ text: string }>({
    resolver: zodResolver(z.string().min(1)),
    reValidateMode: "onChange",
  });

  return (
    <div>
      <Space align="center" style={{ marginBottom: 8 }}>
        <Image
          preview={false}
          src={(researchAssistantSvg as { src: string }).src}
          width={20}
          height={20}
          alt={`Research Assistant svg`}
        />
        <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
          Research Assistant
        </Typography.Paragraph>
      </Space>
      <Typography.Title level={2} style={{ fontWeight: 400, fontSize: 34 }}>
        {loading ? <LoadingAnimation /> : question}
      </Typography.Title>
      <form
        onSubmit={form.handleSubmit((formValue) => {
          onSubmit(formValue.text);
          form.reset();
        })}
      >
        <Controller
          control={form.control}
          name="text"
          render={({ field: { value, onChange } }) => (
            <Input
              value={value}
              variant="borderless"
              onChange={onChange}
              autoFocus
              size="large"
              placeholder="Type your answer here and press enter..."
              onPressEnter={(e) => {
                e.preventDefault();
                onSubmit(value);

                form.reset();
              }}
            />
          )}
        />
      </form>
    </div>
  );
};
