import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { addAudienceNew } from "store/modules/audiences/actions/new-ui";
import { addCustomScript } from "store/modules/custom-script/actions";
import { addProblemsNew } from "store/modules/problems/actions/new-ui";
import { addResearchGoal } from "store/modules/research-goal/actions";
import { addSolutionNew } from "store/modules/solutions/actions/new-ui";
import { ResearchPlanProps } from "templates/index/workspace-page/content/new-ui/inputs-selection/research-plan";

type UseHandleNewStudyProps = {
  assistantData: ResearchPlanProps["assistantData"];
  setIsReady: (isReady: boolean) => void;
};

export const useResearchAssistantReady = (props: UseHandleNewStudyProps) => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector((state) => state.projects.data.project?.id);

  const { assistantData, setIsReady } = props;

  return useCallback(() => {
    if (!projectId) return;
    setIsReady(true);

    if (assistantData?.audience !== undefined) {
      if (Array.isArray(assistantData.audience)) {
        assistantData.audience.forEach((audience) =>
          dispatch(addAudienceNew({ projectId: projectId, description: audience }))
        );
      } else {
        dispatch(addAudienceNew({ projectId: projectId, description: assistantData?.audience }));
      }
    }

    if (assistantData?.solution !== undefined) {
      if (Array.isArray(assistantData.solution)) {
        assistantData.solution.forEach((solution) =>
          dispatch(addSolutionNew({ projectId: projectId, description: solution }))
        );
      } else {
        dispatch(addSolutionNew({ projectId: projectId, description: assistantData?.solution }));
      }
    }

    if (assistantData?.problems !== undefined) {
      dispatch(
        addProblemsNew({
          projectId: projectId,
          descriptions: Array.isArray(assistantData?.problems)
            ? assistantData?.problems
            : [assistantData?.problems],
        })
      );
    }

    if (assistantData?.researchGoal !== undefined) {
      dispatch(addResearchGoal({ projectId: projectId, description: assistantData?.researchGoal }));
    }

    if (assistantData?.customScript !== undefined) {
      dispatch(addCustomScript(assistantData?.customScript));
    }
  }, [assistantData, dispatch, setIsReady, projectId]);
};
