import { Button, Input, Modal, Typography } from "antd";
import { getAnalytics } from "logic/analytics/analytics";
import { useCallback, useState } from "react";
import { useAppDispatch } from "store/hooks";
import useDebouncedCallback from "logic/hooks/use-debounce";
import { generateSummary } from "store/modules/summaries/actions";
import { InsightsPanelTabKeys, useStudyContainerContext } from "../../contexts/insights-panel";

type CreateReportModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

export const CreateReportModal: React.FC<CreateReportModalProps> = ({ visible, setVisible }) => {
  // dispatch
  const dispatch = useAppDispatch();
  // analytics
  const analytics = getAnalytics();
  // context
  const { setInsightsPanelTab, setCurrentSummaryIndex } = useStudyContainerContext();

  // local state
  const [value, setValue] = useState("");

  const handleGenerate = useDebouncedCallback(() => {
    analytics.track("workspace:study:create-report-modal:generate");

    const computedValue = value.length ? value : undefined;

    // close modal
    setVisible(false);
    // dispatch generate summary action
    dispatch(generateSummary({ summarizationFocus: computedValue }));
    // set insights panel tab to insights
    setInsightsPanelTab(InsightsPanelTabKeys.SUMMARY);
    // set current summary index to 0
    setCurrentSummaryIndex(0);
  });

  const handleCancel = useCallback(() => {
    analytics.track("workspace:study:create-report-modal:cancel");
    setValue("");
    setVisible(false);
  }, [setValue, setVisible, analytics]);

  return (
    <Modal
      title={"Generate report"}
      open={visible}
      width={"40%"}
      maskClosable={false}
      onCancel={() => {
        setVisible(false);
        analytics.track("workspace:study:create-report-modal:close");
      }}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleGenerate}>
          Create report
        </Button>,
      ]}
    >
      <Typography.Paragraph>
        {"If you want to set a specific focus to the report please insert it bellow."}
        <br />
        {`Otherwise just click the Create report button.`}
      </Typography.Paragraph>
      <Input.TextArea
        autoFocus
        className="custom-script-text-area"
        autoSize={{ minRows: 3, maxRows: 6 }}
        placeholder={"Insert a focus for the report"}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        style={{
          resize: "none",
          width: "100%",
        }}
      />
      <Typography.Paragraph />
    </Modal>
  );
};
