import { Typography, Card } from "antd";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";

type ConceptMatchesCardProps = {
  selectedConcept: string;
  selectedInterviewee: string;
};

export const ConceptMatchesCard: React.FC<ConceptMatchesCardProps> = ({
  selectedConcept,
  selectedInterviewee,
}) => {
  const studyVisualizationData = useAppSelector(
    (state) => state.studyVisualization.data?.studyVisualization
  );

  const match = useMemo(() => {
    if (!studyVisualizationData) return;

    const currMatches = studyVisualizationData.concepts_matched;

    return currMatches.filter(
      (match) =>
        match.id !== "All" &&
        match.concept === selectedConcept &&
        match.full_name === selectedInterviewee
    )[0];
  }, [studyVisualizationData, selectedConcept, selectedInterviewee]);

  if (!match)
    return (
      <Card style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Typography.Paragraph type="secondary" strong style={{ marginBottom: 0 }}>
          This interviewee does not have any matches for this concept
        </Typography.Paragraph>
      </Card>
    );

  return (
    <>
      <Typography.Paragraph strong style={{ marginBottom: 16 }}>
        Potential Concept Matches
      </Typography.Paragraph>
      <Card>
        <Typography.Paragraph strong>
          Concept: <Typography.Text style={{ fontWeight: 400 }}>{match?.concept}</Typography.Text>
        </Typography.Paragraph>

        <Typography.Paragraph strong>
          Concept Score:{" "}
          <Typography.Text style={{ fontWeight: 400 }}>{match?.concept_score}</Typography.Text>
        </Typography.Paragraph>

        <Typography.Paragraph strong>
          Score Rationale:{" "}
          <Typography.Text style={{ fontWeight: 400 }}>{match?.score_rationale}</Typography.Text>
        </Typography.Paragraph>

        <Typography.Paragraph strong>
          Most relevant quote:{" "}
          <Typography.Text
            italic
            style={{ fontWeight: 400 }}
          >{`"${match?.highlights || ""}"`}</Typography.Text>
        </Typography.Paragraph>
      </Card>
    </>
  );
};
