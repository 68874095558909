export function transformToReadable(inputString: string): string {
  const result: string[] = [];

  inputString.split("").forEach((char, index) => {
    if (index === 0) {
      result.push(char.toUpperCase());
    } else if (char === char.toUpperCase()) {
      result.push(" ", char);
    } else {
      result.push(char);
    }
  });

  return result.join("");
}
