import { Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";

export const LoadingAnimation: React.FC = () => {
  const [count, setCount] = useState(1);

  const content = useMemo(() => {
    const dots = ".".repeat(count);
    return dots;
  }, [count]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((count) => (count === 3 ? 1 : count + 1));
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <Typography.Title level={2} style={{ fontWeight: 400 }}>
      {content}
    </Typography.Title>
  );
};
