import { useEffect, useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { GenerateSyntheticUsers } from "../generate-synthetic-users";
import { InsightsPanel } from "../insight-panel";
import { StudyContainerTabs, useStudyContainerContext } from "../../contexts/insights-panel";
import { InputsSelection } from "../new-ui/inputs-selection";
import { InterviewMode } from "../new-ui";
import { ProjectSettings } from "store/modules/projects/types";

export const StudyContainerComponent: React.FC = () => {
  const syntheticUsersData = useAppSelector((state) => state.syntheticUsers.data);
  const studyStrategy = useAppSelector((state) => state.study.data?.studyStrategy);

  const studyType = useMemo(() => {
    switch (studyStrategy) {
      case InterviewMode.DYNAMIC_INTERVIEW:
        return ProjectSettings.DynamicInterviewMode;
      case InterviewMode.PROBLEM_EXPLORATION:
        return ProjectSettings.ProblemExplorationMode;
      case InterviewMode.CUSTOM_SCRIPT:
        return ProjectSettings.CustomScriptMode;
      case InterviewMode.CONCEPT_TESTING:
        return ProjectSettings.SolutionFeedbackMode;
      case InterviewMode.MESSAGE_TESTING:
        return ProjectSettings.SolutionFeedbackMode;
      default:
        return ProjectSettings.DynamicInterviewMode;
    }
  }, [studyStrategy]);

  const { studyContainerTab, resetStudyContainerContext } = useStudyContainerContext();

  useEffect(() => {
    if (!syntheticUsersData) {
      // reset workspace page context when synthetic users are not available
      resetStudyContainerContext();
    }
  }, [syntheticUsersData, resetStudyContainerContext]);

  return (
    <>
      {studyContainerTab === StudyContainerTabs.ResearchPlan && (
        <InputsSelection isReadOnly={true} interviewMode={studyType} />
      )}

      {studyContainerTab === StudyContainerTabs.InsightsPanel && <InsightsPanel />}

      {studyContainerTab === StudyContainerTabs.SyntheticUsers && <GenerateSyntheticUsers />}
    </>
  );
};
