import { ReactNode, useState } from "react";

export function TextWithNewLines(props: { children: ReactNode }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <span
      className={`text-with-new-lines ${isExpanded ? "expanded" : ""}`}
      onClick={() => setIsExpanded((prev) => !prev)}
      style={{ cursor: "pointer", whiteSpace: "pre-wrap" }}
    >
      <style jsx>
        {`
          .text-with-new-lines {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
          .text-with-new-lines.expanded {
            -webkit-line-clamp: initial;
          }
        `}
      </style>
      {props.children}
    </span>
  );
}
