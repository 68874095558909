import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { Button, Dropdown, Tooltip } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { getAnalytics } from "logic/analytics/analytics";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { exportSummary } from "store/modules/summaries/actions";
import { useStudyContainerContext } from "../../contexts/insights-panel";

export const ExportButton: React.FC = () => {
  const dispatch = useAppDispatch();

  const summary = useAppSelector((state) => state.summary);
  const history = useAppSelector((state) => state.history);
  const analytics = getAnalytics();

  const { currentSummaryIndex } = useStudyContainerContext();

  const currentSummary = useMemo(
    () => summary.data && summary.data[currentSummaryIndex],
    [summary, currentSummaryIndex]
  );

  const disabled = useMemo(
    () => !currentSummary?.summary.length || summary.loading,
    [summary, currentSummary]
  );

  const handleExport = (fileExtension: string) => {
    if (!currentSummary) return;

    const { summaryId } = currentSummary;
    const currentStudyId = summary.data?.find((s) => s.summaryId === summaryId)?.studyId;
    const currentStudyDescription =
      history.data?.find((s) => s.id === currentStudyId)?.description || summaryId;

    dispatch(
      exportSummary({ summaryId, studyDescription: currentStudyDescription, fileExtension })
    );
    analytics.track("workspace:study:export", { study_id: summaryId, format: fileExtension });
  };

  return (
    <Dropdown
      disabled={disabled}
      trigger={["click"]}
      menu={{
        items: [
          {
            key: "export-summary-csv",
            label: "CSV",
            onClick: (e) => {
              e.domEvent.stopPropagation();
              handleExport("csv");
            },
          },
          {
            key: "export-summary-xlsx",
            label: "XLSX",
            onClick: (e) => {
              e.domEvent.stopPropagation();
              handleExport("xlsx");
            },
          },
          {
            key: "export-summary-json",
            label: "JSON",
            onClick: (e) => {
              e.domEvent.stopPropagation();
              handleExport("json");
            },
          },
        ],
      }}
    >
      <Tooltip
        placement="bottomLeft"
        title={
          disabled
            ? "You need to generate user interviews and a summary before you can export your study"
            : ""
        }
      >
        <Button
          disabled={disabled}
          title="Export study"
          className="btn btn-link link-body"
          style={{ marginRight: 12 }}
          icon={<AntIcon icon={faFileDownload} />}
        >
          Export study
        </Button>
      </Tooltip>
    </Dropdown>
  );
};
