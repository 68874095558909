import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Card, Input, Typography } from "antd";
import { ErrorFeedback } from "components/shared/error-feedback";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { Container } from "components/ui-kit/components/container";
import { Spinner } from "components/ui-kit/components/spinner";
import { useToken } from "components/ui-kit/core/token";
import { getAnalytics } from "logic/analytics/analytics";
import { isTransportFailure } from "logic/internals/transports/transported-data/is-transport-failure";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactMarkdown from "react-markdown";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  addSummaryConversation,
  connectSummaryConversationsWS,
  getSummaryConversations,
} from "store/modules/summaries-conversations/actions";
import { z } from "zod";

type SummaryFollowUpContainerProps = {
  summaryId: string;
  readonly?: boolean;
};

export const SummaryFollowUpContainer: React.FC<SummaryFollowUpContainerProps> = ({
  summaryId,
  readonly = false,
}) => {
  const dispatch = useAppDispatch();
  const summaryConversations = useAppSelector((state) => state.summaryConversations);
  const token = useToken();
  const analytics = getAnalytics();

  const isReportResearchAssistantFollowUpFlagEnabled = useFeatureFlagEnabled('report-research-assistant-follow-up');

  const sortedSummaryConversations = useMemo(() => {
    // spread the array to avoid direct state mutation
    const currInterviews = [...(summaryConversations.data ? summaryConversations.data : [])];

    return currInterviews?.sort(
      (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );
  }, [summaryConversations.data]);

  const form = useForm<{ text: string }>({
    resolver: zodResolver(
      z.object({
        text: z.string().min(1),
      })
    ),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    dispatch(getSummaryConversations({ summaryId: summaryId }));
  }, [summaryId, dispatch]);

  return (
    <>
      {/* Error state */}
      {isTransportFailure(summaryConversations.error) && (
        <ErrorFeedback error={summaryConversations.error} style={{ marginBottom: token.margin }} />
      )}

      {/* Loading state */}
      {summaryConversations.loading && (
        <Spinner style={{ marginBottom: token.margin, marginTop: token.margin }} size="large" />
      )}

      {/* Conversations list */}
      <div
        style={{
          flex: "1 1 auto",
          marginBottom: readonly ? token.margin : "initial",
        }}
      >
        {!!sortedSummaryConversations &&
          sortedSummaryConversations.map(
            (conversation, index) =>
              index % 2 == 0 && (
                <Card
                  key={index}
                  style={{
                    marginTop: token.margin,
                    borderBlockColor: "transparent",
                    borderLeftColor: "transparent",
                    borderRightColor: "transparent",
                    backgroundColor: token.colorFillSecondary,
                  }}
                >
                  {/* even index entries are the questions - To be refactored after BE endpoint logic changes */}
                  <Typography.Title level={4}>{conversation.message}</Typography.Title>
                  {!!summaryConversations.data &&
                    !!summaryConversations.data[index + 1]?.message && (
                      <ReactMarkdown>
                        {summaryConversations.data[index + 1]?.message || ""}
                      </ReactMarkdown>
                    )}
                </Card>
              )
          )}
      </div>

      {/* New Question Input */}
      {!readonly && (
        <div>
          <form
            style={{
              position: "sticky",
              bottom: 0,
            }}
            onSubmit={form.handleSubmit((formValue) => {
              analytics.track("workspace:study:summary:follow-up:chat", {
                summaryId: summaryId,
              });

              form.reset();

              setTimeout(() => {
                window.scrollBy({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                });
              }, 0);

              // add the new question to state
              dispatch(addSummaryConversation(formValue.text));
              // connect to the WS
              dispatch(connectSummaryConversationsWS(
                summaryId, 
                formValue.text, 
                isReportResearchAssistantFollowUpFlagEnabled ? '/summaries/researchAssistantFollowUp/stream/' : undefined
              ));
            })}
          >
            <Card
              style={{
                marginTop: token.margin,
                borderBlockColor: "transparent",
                borderLeftColor: "transparent",
                borderRightColor: "transparent",
              }}
            >
              <Container style={{ maxWidth: "800px" }}>
                <Controller
                  control={form.control}
                  name="text"
                  render={({ field: { value, onChange } }) => (
                    <Input
                      autoComplete="off"
                      id="follow-up-template-chat-input"
                      disabled={summaryConversations.loading}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onPaste={(e) => {
                        e.preventDefault();
                        const pastedData = e.clipboardData.getData("text");
                        onChange(pastedData);
                      }}
                      suffix={
                        <Button
                          htmlType="submit"
                          disabled={
                            !form.formState.isValid ||
                            !Object.keys(form.formState.dirtyFields).length ||
                            summaryConversations.loading
                          }
                          icon={<AntIcon icon={faPaperPlane} />}
                          type="text"
                        />
                      }
                    />
                  )}
                />
              </Container>
            </Card>
          </form>
        </div>
      )}
    </>
  );
};
