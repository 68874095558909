/* eslint-disable */
import { useRef } from "react";

type DebouncedCallback<T extends (...args: any[]) => any> = (...args: Parameters<T>) => void;

function useDebouncedCallback<T extends (...args: any[]) => any>(
  callback: T,
  delay = 300
): DebouncedCallback<T> {
  const isDebouncingRef = useRef(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedCallback: DebouncedCallback<T> = (...args: Parameters<T>) => {
    if (!isDebouncingRef.current) {
      isDebouncingRef.current = true;
      callback(...args); // Execute the callback if not in debounced state
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Clear any existing timeout
      }
      timeoutRef.current = setTimeout(() => {
        isDebouncingRef.current = false;
      }, delay); // debounce delay
    }
  };

  return debouncedCallback;
}

export default useDebouncedCallback;
/* eslint-enable */
