/* eslint-disable @next/next/no-img-element */
import { Button, Flex, Space, Typography } from "antd";
import React from "react";
import interviewFailed1 from "assets/interview-failed-1.svg";
import interviewFailed2 from "assets/interview-failed-2.svg";

type InterviewFailedProps = {
  onRetry: () => void;
};

export const InterviewFailed: React.FC<InterviewFailedProps> = ({ onRetry }) => {
  return (
    <Flex align="center" justify="center" style={{ marginTop: "44px" }}>
      <img
        style={{ marginBottom: "20px" }}
        height="180px"
        src={(interviewFailed1 as { src: string }).src}
        alt="Google"
      />
      <Space direction="vertical" size="middle" align="center">
        <Typography.Paragraph strong>Interview failed</Typography.Paragraph>
        <Button type="primary" onClick={onRetry} style={{ marginInline: 32 }}>
          Retry
        </Button>
      </Space>
      <img
        style={{ marginBottom: "5px" }}
        height="200px"
        src={(interviewFailed2 as { src: string }).src}
        alt="Google"
      />
    </Flex>
  );
};
