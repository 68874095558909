import { Carousel, Image, Typography } from "antd";
import { useToken } from "components/ui-kit/core/token";
import educate1 from "./assets/educate-1.svg";
import educate2 from "./assets/educate-2.svg";
import educate3 from "./assets/educate-3.svg";
import lightning from "./assets/lightning.svg";
import soc2 from "/assets/soc2.svg";

export function EducationalLoading() {
  const token = useToken();

  return (
    <>
      <style jsx global>{`
        .ant-carousel .slick-dots li button {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${token.colorPrimary};
        }
        .ant-carousel .slick-dots li.slick-active button {
          background-color: ${token.colorPrimary};
        }
      `}</style>
      <div
        style={{
          padding: token.padding,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Image
            src={(lightning as { src: string }).src}
            preview={false}
            alt="Scrollable"
            style={{
              width: "27px",
              height: "33px",
            }}
          />
          <Typography.Title level={5}>Latest Feature</Typography.Title>
        </div>
        <div
          style={{
            width: "587px",
            height: "333px",
          }}
        >
          <Carousel autoplay>
            <div>
              <Typography.Title level={2}>Annotate your interviews</Typography.Title>
              <Image
                src={(educate1 as { src: string }).src}
                preview={false}
                alt="Scrollable"
                style={{
                  width: "587px",
                  height: "239px",
                  paddingTop: token.padding,
                  paddingBottom: token.padding,
                }}
              />
            </div>
            <div>
              <Typography.Title level={2}>Chat with your report / insights</Typography.Title>
              <Image
                src={(educate2 as { src: string }).src}
                preview={false}
                alt="Scrollable"
                style={{
                  width: "587px",
                  height: "239px",
                  paddingTop: token.padding,
                  paddingBottom: token.padding,
                }}
              />
            </div>
            <div>
              <Typography.Title level={2}>Import your own data</Typography.Title>
              <Image
                src={(educate3 as { src: string }).src}
                preview={false}
                alt="Scrollable"
                style={{
                  width: "587px",
                  height: "239px",
                  paddingTop: token.padding,
                  paddingBottom: token.padding,
                }}
              />
            </div>
          </Carousel>
        </div>
        <div>
          <Image
            src={(soc2 as { src: string }).src}
            preview={false}
            alt="Scrollable"
            style={{
              width: "125px",
              height: "52px",
              opacity: 0.2,
            }}
          />
        </div>
      </div>
    </>
  );
}
