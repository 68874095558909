import { useAuthState } from "logic/auth/auth-state";
import { CSSProperties, ReactNode, useMemo } from "react";
import { useSignupRequiredAlert } from "./signup-required-alert";
import { useAppSelector } from "store/hooks";
import { WorkspaceMode } from "store/modules/workspaces/types";
import { useOngoingWalkthrough } from "logic/hooks/ongoing-walkthrough";

export function SignupRequiredTrigger(props: { children: ReactNode; style?: CSSProperties }) {
  const workspaceMode = useAppSelector((state) => state.workspaces.data.workspaceMode);

  const auth = useAuthState();

  const signupRequiredAlert = useSignupRequiredAlert();

  const user = auth.session.data?.user;

  const { ongoingWalkthrough } = useOngoingWalkthrough();

  const isSignUpRequired = useMemo(
    () =>
      workspaceMode === WorkspaceMode.AnonymousUser &&
      (!user || user.isAnonymous) &&
      !ongoingWalkthrough,
    [workspaceMode, user, ongoingWalkthrough]
  );

  return (
    <>
      <style jsx global>{`
        .signup-required-trigger-enabled {
          cursor: pointer;
        }
        .signup-required-trigger-enabled > * {
          pointer-events: none;
        }
      `}</style>
      <div
        className={isSignUpRequired ? "signup-required-trigger-enabled" : undefined}
        style={props.style}
        onClick={() => isSignUpRequired && signupRequiredAlert.showSignupRequiredAlert()}
      >
        {props.children}
      </div>
    </>
  );
}
