// React
import { useState } from "react";
// Components
import { Button, Col, Input, Row, Typography } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
// Icon
import { faFileEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
// Hooks
// Utils
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons/lib/icons";
import { useToken } from "components/ui-kit/core/token";
import { transformToReadable } from "logic/internals/utils/transformString";
import { GeneratedSyntheticUser } from "store/modules/synthetic-users/types";

interface RequirementInputProps {
  data: GeneratedSyntheticUser;
  requirement: string;
  value: string;
  onDelete?: (data: GeneratedSyntheticUser, requirementKey: string) => void;
  onUpdate?: (data: GeneratedSyntheticUser, requirementKey: string, newValue: string) => void;
  isEditable: boolean;
}

export function RequirementInput({
  data,
  requirement,
  value,
  onDelete,
  onUpdate,
  isEditable,
}: RequirementInputProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [requirementInput, setRequirementInput] = useState<string>(value);
  const token = useToken();

  const handleUpdate = () => {
    if (onUpdate && requirementInput.trim().length != 0) {
      onUpdate(data, requirement, requirementInput);
      setIsEditing(false);
    }
  };

  return (
    <div>
      <Row align="middle" style={{ padding: "6px 0" }}>
        <Col span={19}>
          <Row>
            <Col>
              <Typography.Paragraph
                strong
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  color: !isEditable ? "black" : token.colorPrimary,
                }}
              >
                {"•"} {transformToReadable(requirement)}:
              </Typography.Paragraph>
            </Col>
            <Col>
              {isEditing && onUpdate ? (
                <Input
                  value={requirementInput}
                  autoFocus
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRequirementInput(e.target.value)
                  }
                  onBlur={() => {
                    setRequirementInput(value); // Reset the input to the original value
                    setIsEditing(false);
                  }}
                  onPressEnter={() => {
                    handleUpdate();
                  }}
                  suffix={
                    <div>
                      <CheckCircleOutlined
                        onMouseDown={() => {
                          handleUpdate();
                        }}
                      />

                      <CloseCircleOutlined
                        style={{ marginLeft: "5px" }}
                        onMouseDown={() => {
                          setRequirementInput(value); // Reset the input to the original value
                          setIsEditing(false); // Exit editing mode
                        }}
                      />
                    </div>
                  }
                  placeholder="Value"
                  status={requirementInput.trim().length == 0 ? "error" : undefined}
                />
              ) : (
                <Typography.Paragraph
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: "5px",
                    color: !isEditable ? "black" : token.colorPrimary,
                  }}
                  onClick={() => isEditable && setIsEditing(true)}
                >
                  {value}
                </Typography.Paragraph>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={5} hidden={!isEditable}>
          {onUpdate && (
            <Button
              style={{ paddingLeft: 6, fontSize: "10px" }}
              size="small"
              type="text"
              icon={<AntIcon icon={faFileEdit} />}
              onClick={() => setIsEditing(true)}
            ></Button>
          )}
          {onDelete && (
            <Button
              style={{ fontSize: "10px" }}
              type="text"
              size="small"
              icon={<AntIcon icon={faTrash} />}
              onClick={() => onDelete(data, requirement)}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}
